import React from "react";
import emailjs from "emailjs-com";
// import Back from "../common/back/Back"; 
import "../css/contact.css";
import img1 from "../img/contactbg.png";

const Contact = () => {
  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2921.4505253997295!2d73.8118666738002!3d18.501829969749213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6619304d16bf6da5%3A0xbb34fddc301d7bcb!2sUpskill%20IT%20Training%20%26%20Placement!5e1!3m2!1sen!2sin!4v1722518151542!5m2!1sen!2sin';


  // function sendEmail(e) {
  //   e.preventDefault();

  //   emailjs.sendForm('service_e1n9upl', 'template_iv50f0a', e.target, '0t4YMQj9EpxpeXCSx')
  //     .then(res => {
  //       console.log(res);
  //       if (res) {
  //          window.location.reload();
  //       }
  //     })
  //     .catch(err => console.log(err));
  // }

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_iae8p4o', 'template_wdlflua', e.target, 'ss92jwsjiWWlkjV1I')
      .then(res => {
        console.log(res);
        if (res) {
           window.location.reload();
        }
      },[])
      .catch(err => console.log(err));
  }
  return (
    <>
      <div className="row">
        <img src={img1} alt="img" width={'100%'} />
      </div>
      <div className="container pt-5 pb-5">
        <div className="row pb-5 ">
          <div className="col-md-6 d-flex justify-content-center mt-2">
            <div className="card shadow text-center p-3" style={{ width: '100%' }}>
              <h5>Contact Us</h5>
              <span className="hor"><i className="fa-solid fa-phone"></i> <a href="tel:+91 8484833677" className="hor">+91 8484833677</a></span>
              <span className="hor"> <i className="fa-regular fa-envelope"></i> <a href="mailto:hr@upskilll.com" className="hor"> hr@upskilll.com</a></span>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center mt-2">
            <div className="card shadow text-center p-2">
              <h5>Address</h5>
              <p className="hor"><i className="fa-solid fa-location-dot"></i> 103, 1st Floor, B Junction, Behind Karve Statue, Next to Kothrud Post Office, Kothrud, Pune-411038
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 shadow p-3 mt-2">
            <iframe
              src={map}
              title="Google Maps Location"
              className="mb-4 mb-md-0"
              style={{ width: "100%", height: "100%", border: "none" }}
            ></iframe>
          </div>
          <div className="col-md-6 mt-2">
            <form className="card p-4 shadow" onSubmit={sendEmail}>
              <center> <h3>Contact Us</h3></center>
              <label htmlFor="name">Your Name<span style={{color:'red'}}>*</span></label>
              <input type="text" name="name" id="name" className="form-control mb-2" placeholder="Enter Name" required />
              <label htmlFor="email">Your Email<span style={{color:'red'}}>*</span></label>
              <input type="text" name="email" id="name" className="form-control mb-2" placeholder="Enter email" required />
              <label htmlFor="phone">Phone Number<span style={{color:'red'}}>*</span></label>
              <input type="text" name="phone" id="phone" className="form-control mb-2" placeholder="Phone No" maxLength="10" minLength="10" onKeyPress={(event) => isNaN(event.target.value + String.fromCharCode(event.which)) && event.preventDefault()} required />
              <label htmlFor="subject">Education<span style={{color:'red'}}>*</span></label>
              <input type="text" name="qualification" id="subject" className="form-control mb-2" placeholder="Enter Education.." required />
              <label for="Year of Passing" >Year of Passing<span style={{color:'red'}}>*</span></label>
              <input name="yop" type="text" className="form-control mb-2" placeholder="Year of Passing" required/>
              <label htmlFor="message">Choose Course<span style={{color:'red'}}>*</span></label>
              <select type="text" name="course" id="message" className="form-control mb-2" required>
                <option value="">Choose Course</option>
                <option value="Web Development">Web Development</option>
                <option value="Software Testing">Software Testing</option>
                <option value="Java Full Stack Development">Java Full Stack Development</option>
                <option value="Python Full Stack Development">Python Full Stack Development</option>
              </select>
              {/* <input  placeholder="Your Message..."  /> */}
              <center><button type="submit" className="btn btn-primary mt-4"><b>Submit</b></button></center>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
