import React from "react";
// import Popup from './Form';
import "../css/style.css";
import img1 from "../img/w1.jpg"
import img2 from "../img/w3.jpg"
import img3 from "../img/w7.jpg"
import img4 from "../img/w8.png"

const Testimonal = () => {
  return (
    <div className="container mb-5">
           <div className="row pt-4 mt-5  pb-3 ">
  <div className="col-md-12 text-center">
    <h1 style={{fontSize:'40px', fontWeight:'600', color:'#F27C00'}}>Why To Choose Us?
  </h1>
  </div>
  </div>
  <div className="row">
  <img src={img4} alt="imag" style={{width:"100%"}}/>
  </div>
    <div className="row pt-3" >
      <div className="col-md-6 col-12" > 
        <img src={img1} alt="imag" style={{width:"100%"}}/>
      </div>
      <div className="col-md-6 col-12">
        <h4>Career Guidance By Expert  Counsellor </h4>
        <p>
We provide individualized career guidance by looking at your education background. Our expert counselor gives you the best advice for which course to choose offering insights and strategies to navigate your professional journey successfully. 

        </p>
<br/>
        <h4>Experienced Instructors </h4>
        <p>
        
We have Experienced trainers those who has more than 10+,17+ years of Corporate and teaching experience. This will make concept understanding much easier.


        </p>
      </div>
    </div>
    <div className="row" >

      <div className="col-md-6 col-12">
        <br/>
      <h4> Doubt Clearing Sessions  </h4>
        <p>       
            We have team of Assistant trainers available 24/7 to guide you for your technical practical queries.
        </p>
        <br/>
        <h4>Real Time Project Exposure </h4>
        <p>
        
We take more than 6 to 7 Real time Projects which will help you to improve Logic building skills and gives you industry level of project exposure.

        </p>
      </div>
      <div className="col-md-6 col-12">
        <img src={img2} alt="imag" style={{width:"100%"}}/>
      </div>
    </div>
    
    
    <div className="row pb-2" >
      <div className="col-md-6 col-12">
        <img src={img3} alt="imag" style={{width:'100%'}}/>
      </div>
      <div className="col-md-6 col-12 pt-2">
        <h4>Mock interview Practice & Soft Skills </h4>
        <p>
        
We take lots of practice dummy interview’s which will boost your confidence and will improve your technical content delivery.
Soft skills sessions are specially designed to improve your Aptitude and English Communication Skills.

        </p>
        <br/>
        <h4>Resume Building</h4>
        <p>
        
Best Resume helps you to get shortlist quicker in the hiring process. We help you to build excellent Resume.

        </p>
      </div>
    </div>
      
    </div>
    
  )
}

export default Testimonal;
