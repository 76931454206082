import React from "react";
import Head from "./Head";
import "../css/header.css";

const Header = () => {
  const openFb = () => {
    window.open('https://www.facebook.com/profile.php?id=61563050616589', '_blank');
  }
  const openLink = () => {
    window.open('https://www.linkedin.com/company/upskill-it-training-placement/?viewAsMember=true', '_blank');
  }
  const openInstaG = () => {
    window.open('https://www.instagram.com/upskill_pune/', '_blank');
  }

  return (
    <>
      <div className="container-fluid bg-white">
        {/* Desktop View: First Row */}
        <div className="row d-none d-md-flex p-1">
          <div className="col-md-6">
            <span className="hor"><i className="fa-solid fa-phone"></i> <a href="tel:+91 8484833677" className="hor">+91 8484833677</a></span>
            <span className="hor"> <i className="fa-regular fa-envelope"></i> <a href="mailto:hr@upskilll.com" className="hor"> hr@upskilll.com</a></span>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <i onClick={openFb} className="fab fa-facebook-f icon"></i>
            <i onClick={openInstaG} className="fab fa-instagram icon"></i>
            <i onClick={openLink} className="fab fa-linkedin icon"></i>
            <i  className="fab fa-youtube icon"></i>
          </div>
        </div>

        {/* Mobile View: Second Row */}
        <div className="row d-flex d-md-none p-1">
          <div className="col-5">
            <span className="hor" style={{fontSize:'13px'}}><i className="fa-solid fa-phone"></i> <a href="tel:+91 8484833677" className="hor">+918484833677</a></span>
          </div>
          <div className="col-7">
            <span className="hor" style={{fontSize:'13px'}}> <i className="fa-regular fa-envelope"></i> <a href="mailto:example@example.com" className="hor">enquiry@upskilll.com</a></span>
          </div>
        </div>
      </div>
      <Head />
    </>
  );
}

export default Header;
