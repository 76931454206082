// src/components/Feedback.js

import React, { useState } from 'react';

const Feedback = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you would typically send the feedback to your server
    console.log({ name, email, message });
    setSubmitted(true);
  };

  return (
    <div className="container mt-5 mb-5">
      <h1 className="text-center">Feedback</h1>
      <p className="text-center">We value your feedback! Please fill out the form below to share your thoughts with us.</p>

      {submitted ? (
        <div className="alert alert-success text-center">
          <h4>Thank you for your feedback!</h4>
          <p>We appreciate you taking the time to help us improve.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="message"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary mt-3">Submit</button>
        </form>
      )}
    </div>
  );
};

export default Feedback;
