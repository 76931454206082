import React from "react"

const Hprice = () => {
  return (
    <>
     
    </>
  )
}

export default Hprice