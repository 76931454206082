import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Terms and Conditions</h1>
      {/* <p className="text-center"><strong>Last Updated: [Date]</strong></p> */}

      <section>
        <p>Welcome to UpSkill Institute! These Terms and Conditions outline the rules and regulations for using our website and services. By accessing or using our website and services, you agree to comply with and be bound by these Terms and Conditions.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By using the UpSkill Institute website and services, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you do not agree with these Terms and Conditions, please do not use our website or services.</p>

        <h2>2. Services Provided</h2>
        <p>UpSkill Institute provides educational and training services, including but not limited to online courses, workshops, and seminars. We reserve the right to modify or discontinue any aspect of our services without prior notice.</p>

        <h2>3. Registration and Accounts</h2>
        <p>To access certain features of our services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
        <p>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately if you become aware of any unauthorized use of your account.</p>

        <h2>4. Payment and Fees</h2>
        <p>Certain services provided by UpSkill Institute may require payment. All fees are non-refundable unless otherwise specified. Payments for courses, workshops, and other services must be made according to the payment terms provided at the time of purchase.</p>

        <h2>5. Content and Intellectual Property</h2>
        <p>All content on the UpSkill Institute website, including but not limited to text, graphics, logos, images, and software, is the property of UpSkill Institute or its content providers and is protected by intellectual property laws. You may not reproduce, distribute, or otherwise use any content from our website without our express written permission.</p>

        <h2>6. User Conduct</h2>
        <p>You agree to use our services in a lawful manner and to comply with all applicable laws and regulations. You shall not engage in any conduct that disrupts or interferes with our services or the experience of other users.</p>

        <h2>7. Third-Party Links</h2>
        <p>Our website may contain links to third-party websites. We are not responsible for the content or practices of these third-party websites. The inclusion of any link does not imply endorsement by UpSkill Institute.</p>

        <h2>8. Limitation of Liability</h2>
        <p>To the fullest extent permitted by law, UpSkill Institute shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our services. Our total liability for any claim arising out of or relating to our services shall be limited to the amount you paid for the service in question.</p>

        <h2>9. Indemnification</h2>
        <p>You agree to indemnify, defend, and hold harmless UpSkill Institute, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or related to your use of our services or your violation of these Terms and Conditions.</p>

        <h2>10. Modifications to Terms</h2>
        <p>UpSkill Institute reserves the right to modify these Terms and Conditions at any time. Any changes will be effective when posted on our website. Your continued use of our services after any changes constitutes your acceptance of the revised Terms and Conditions.</p>

        <h2>11. Governing Law</h2>
        <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of laws principles.</p>

        <h2>12. Contact Us</h2>
        <p>If you have any questions or concerns regarding these Terms and Conditions, please contact us at:</p>
        <p><strong>UpSkill Institute</strong><br />
           Email: enquiry@upskilll.com<br />
           Phone: +91 8484833677<br />
           Address: B Junction 103, 1st Floor, Near Karve Statue, Next to Kothrud Post Office, Kothrud, Pune-411038</p>
      </section>
    </div>
  );
}

export default TermsAndConditions;
