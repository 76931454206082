import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoImage from "../img/brand-logo.png";
import "../css/header.css";

const Head = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="container-fluid">
      {/* Desktop Header */}
      <div className="row d-none d-md-flex" style={{ height: '70px', background: 'rgb(218 232 247)' }}>
        <div className="col-md-4 ">
          <Link to="/" className="nav-link"><img id="img1" src={logoImage} alt="brand logo" style={{width:'55%'}}/></Link>
        </div>
        <div className="col-md-8 d-flex justify-content-end">
          <ul className="nav pt-3">
            <li className="nav-item">
              <Link to="/" className="nav-link">Home</Link>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Courses</Link>
              <ul className="trp">
                <Link to="/Sjava" style={{ textDecoration: 'none' }}><li>Java Full Stack</li></Link>
                <Link to="/Spython" style={{ textDecoration: 'none' }}><li>Python Full Stack</li></Link>
                <Link to="/Sstesting" style={{ textDecoration: 'none' }}><li>Software Testing</li></Link>
                <Link to="/Swebd" style={{ textDecoration: 'none' }}><li>Web Development</li></Link>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link">About Us</Link>
            </li>
            <li className="nav-item">
              <Link to="/corporates" className="nav-link">Corporate Training</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Mobile Header */}
      <div className="row d-flex d-md-none p-1" style={{ height: '70px', background: 'rgb(218 232 247)' }}>
        <div className="col-10 pt-2">
          <Link to="/" className="nav-link"><img id="img1" src={logoImage} alt="brand logo" style={{width:'50%'}}/></Link>
        </div>
        <div className="col-2 d-flex justify-content-end">
          <i onClick={toggleMobileMenu} style={{ fontSize: '23px', cursor: 'pointer', paddingTop: '20px' }} className="fa-solid fa-bars"></i>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
        <div className="row" style={{ height: '100%', background: '#C0CEDE' }}>
          <div className="col-12 align-items-start p-3">
            <ul className="nav row">
            <div className="col-10 pt-2 mb-3">
          <Link to="/" className="nav-link"><img id="img1" src={logoImage} alt="brand logo" style={{width:'80%'}}/></Link>
        </div>
              <li className="nav-item col-12">
                <Link to="/" className="nav-link" onClick={() => setMobileMenuOpen(false)}>Home</Link>
              </li>
              <li className="nav-item col-12">
                <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Courses</Link>
                <ul className="trp">
                  <Link to="/Sjava" style={{ textDecoration: 'none' }} onClick={() => setMobileMenuOpen(false)}><li>Java Full Stack</li></Link>
                  <Link to="/Spython" style={{ textDecoration: 'none' }} onClick={() => setMobileMenuOpen(false)}><li>Python Full Stack</li></Link>
                  <Link to="/Sstesting" style={{ textDecoration: 'none' }} onClick={() => setMobileMenuOpen(false)}><li>Software Testing</li></Link>
                  <Link to="/Swebd" style={{ textDecoration: 'none' }} onClick={() => setMobileMenuOpen(false)}><li>Web Development</li></Link>
                </ul>
              </li>
              <li className="nav-item col-12">
                <Link to="/about" className="nav-link" onClick={() => setMobileMenuOpen(false)} >About Us</Link>
              </li>
              <li className="nav-item col-12">
                <Link to="/corporates" className="nav-link" onClick={() => setMobileMenuOpen(false)}>Corporate Training</Link>
              </li>
              <li className="nav-item col-12">
                <Link to="/contact" className="nav-link" onClick={() => setMobileMenuOpen(false)}>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Head;
