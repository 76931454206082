import React from 'react';
import "./App.css";
import Header from "./components/operations/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./components/operations/About";
import CourseHome from "./components/operations/CourseHome";
import Contact from "./components/operations/Contact";
import Footer from "./components/operations/Footer";
import Home from "./components/operations/Home";
import JavaCourse from "./components/operations/Javacourse";
import PythonCourse from "./components/operations/Pytoncourse";
import WebTech from "./components/operations/WebTechcourse";
import Carporate from "./components/operations/Corporates";
import Testing from "./components/operations/Stesting";
import Sjava from "./components/operations/Sjava";
import Spython from "./components/operations/Spython";
import Sstesting from "./components/operations/Sstesting";
import Swebd from "./components/operations/Swebd";
import TermsAndConditions from './components/operations/TermsAndConditions';
import PrivacyPolicy from './components/operations/PrivacyPolicy';
import Feedback from './components/operations/Feedback';

function App() { 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const openWhatsApp = () => {
    window.open('http://wa.me/8484833677?text=Hello,%20I%20am%20interested%20in%20learning%20more%20about%20the%20courses%20offered%20by%20UpSkill%20Institute.%20Could%20you%20please%20provide%20me%20with%20more%20details?%20Thank%20you!', '_blank');

  };

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/courses' element={<CourseHome />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/javacourse' element={<JavaCourse />} />
          <Route path='/pythoncourse' element={<PythonCourse />} />
          <Route path='/webtechcourse' element={<WebTech />} />
          <Route path='/corporates' element={<Carporate />} />
          <Route path="/testing" element={<Testing />} />
          <Route path="/Sjava" element={<Sjava />} />
          <Route path="/Swebd" element={<Swebd />} />
          <Route path="/Spython" element={<Spython />} />
          <Route path="/Sstesting" element={<Sstesting />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions/>} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
          <Route path="/Feedback" element={<Feedback/>} />
        </Routes>
        <Footer />
        <button className="back-to-top" onClick={scrollToTop} aria-label="Back to top">
          <i className="fab fa-whatsapp" id="whatsapp-icon" onClick={(e) => { e.stopPropagation(); openWhatsApp(); }}></i>
          <i className="fa-solid fa-arrow-up"></i>
        </button>
      </Router>
    </>
  );
}

export default App;
