import React from "react";
// import Popup from './Form';
// import { awrapper } from "../../dummydata"
// import img1 from "../img/contact2.jpg"
import "../css/style.css"
import img2 from "../img/persistent.webp"
import img3 from "../img/1683590388-hcl-logo-1976.avif"
import img4 from "../img/birlasoft.png"
import img5 from "../img/techm.png"
import img6 from "../img/hexa.png"
import img7 from "../img/admos.png"

const Awrapper = () => {

  // const [isPopupVisible, setIsPopupVisible] = useState(false);

  // const togglePopup = () => {
  //   setIsPopupVisible(!isPopupVisible);
  // };

  return (
    <div className="container-fluid mt-5" style={{background:'#DDE3E2'}}>
  
      <div className="row p-3 justify-content-center awr" >
      {/* <div className="row p-3"  style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.67), rgba(0, 0, 0, 0.67)), url(${img1})` }}> */}
        
        <h1 style={{}}>Our Top Hiring Partners
        </h1>          
        </div>
        
          <div className="row">
            <div className="col-md-2 col-6"><img src={img2} style={{width:'100%'}} alt="patners"/></div>
            <div className="col-md-2 col-6"><img src={img3} style={{width:'100%'}} alt="patners"/></div>
            <div className="col-md-2 col-6"><img src={img4} style={{width:'100%'}} alt="patners"/></div>
            <div className="col-md-2 col-6"><img src={img5} style={{width:'100%'}} alt="patners"/></div>
            <div className="col-md-2 col-6"> <img src={img6} style={{width:'100%'}} alt="patners"/></div>
            <div className="col-md-2 col-6"><img src={img7} style={{width:'100%'}} alt="patners"/></div>
          </div>
        
     
      {/* <div className="row pt-5">
        <div className="col-md-8 text-center">
          <div className="row justify-content-center"><p style={{fontSize:'34px'}}>
          Learn the Latest Programming languages from No. 1</p></div>
          <div className="row justify-content-center"><p style={{fontSize:'34px'}}>Training Academy in Pune <b>& Upgrade Your Career.</b>
          </p></div>
        </div>
        <div className="col-md-4 ">
        <center><button onClick={togglePopup} style={{background:'#F07D00', height:'50px', width:'60%', borderRadius:'25px'}} className="btn btn-secondary">Get Started<i className="fa fa-long-arrow-alt-right"></i></button>
          </center>
          {isPopupVisible && <Popup handleClose={togglePopup} />}
        </div>
      </div>  */}
      {/* <section className='awrapper' style={{marginTop:'27px'}}>
        <div className='container grid'>
          {awrapper.map((val) => {
            return (
              <div className="row" style={{width:'100%'}}>
              <div className='col-md-2' >
                <div className="row" style={{width:'100%'}}>
                  <div className="col-md-1">
                    <img style={{background: '#CCE6FF', borderRadius:'15px', }} src={val.cover} alt='' />
                    </div>
                  <div className="col-md-8">
                  <h1>{val.data}</h1>
                  <h3>{val.title}</h3>
                  </div>
                </div>
               
              </div>
              </div>
            )
          })}
        </div>
      </section> */}
    </div>
  )
}

export default Awrapper