import React from "react";
import logo from "../img/brand-logo.png";
import { Link } from "react-router-dom";

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2921.4505253997295!2d73.8118666738002!3d18.501829969749213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6619304d16bf6da5%3A0xbb34fddc301d7bcb!2sUpskill%20IT%20Training%20%26%20Placement!5e1!3m2!1sen!2sin!4v1722518151542!5m2!1sen!2sin';


  const openFb = () => {
    window.open('https://www.facebook.com/profile.php?id=61563050616589', '_blank');
  }
  const openLink = () => {
    window.open('https://www.linkedin.com/company/upskill-it-training-placement/?viewAsMember=true', '_blank');
  }
  const openInsta = () =>{
    window.open('https://www.instagram.com/upskill_pune?igsh=MWo5aXQ1dzJubXRxbg==', '_blank');
  }
  
  return (
    <>
    <div className="row" style={{background: '#C0CEDE', color: '#000'}}> 
    <div className="container pt-4 px-4" >      
      <style>
        {`
        li {
          padding-bottom: 5px; /* Changed from '5px' to 5px without quotes */
          color: #000;
          font-weight: 400;
          font-size: 18px;
          }
          Link{
            text-decoration:none
            }
        `}
      </style>
      <div className="row">
        <div className="col-md-4 col-12">
          <div className="row">
            <img src={logo} style={{ width: "250px" }} alt="footer logo" />
          </div>
          <div className="row">
          <div className="col-1 col-md-1">
  <i onClick={(e) => { e.stopPropagation(); openFb(); }} className="fab fa-facebook-f icon me-2"></i>
</div>
            <div className="col-1 col-md-1">
              <i onClick={(e) => { e.stopPropagation(); openLink(); }} className="fab fa-linkedin icon me-2"></i>
            </div>
            <div className="col-1 col-md-1">
            <i onClick={(e) => { e.stopPropagation(); openInsta(); }} className="fab fa-instagram icon me-2"></i>
            </div>
            <div className="col-1 col-md-1">
              <i className="fab fa-youtube icon"></i>
            </div>
          </div>
          <div className="row pt-3">
            <p className="col-md-8" style={{fontSize:'15px'}}>
            103, 1st Floor, B Junction, Behind Karve Statue, Next to Kothrud Post Office, Kothrud, Pune-411038

            </p>
          </div>
        </div>
        <div className="col-md-2 col-12">
          <div className="row">
            <ul >
              <h5>Explore</h5>
              <Link onClick={scrollToTop}  to="/about"><li style={{fontSize:'15px'}}>About Us</li></Link>
            <Link onClick={scrollToTop} to="/courses"><li style={{fontSize:'15px'}}>Courses</li></Link>
            <Link onClick={scrollToTop} to="/contact"><li style={{fontSize:'15px'}}>Contact us</li></Link>
            <Link onClick={scrollToTop} to="/corporates"><li style={{fontSize:'15px'}}>Corporates Training</li></Link>
            </ul>
          </div>
        </div>
        <div className="col-md-2 col-12">
          <ul>
            <h5>Quick Links</h5>
            {/* <Link to="/contact"><li>Contact Us</li></Link> */}
            <Link onClick={scrollToTop} to="/TermsAndConditions"><li style={{fontSize:'15px'}}>Terms & Conditions</li></Link>
            <Link onClick={scrollToTop} to="/PrivacyPolicy"><li style={{fontSize:'15px'}}>Privacy</li></Link>
            {/* <Link onClick={scrollToTop} to="#"><li style={{fontSize:'15px'}}>Feedbacks</li></Link> */}
            {/* <Link onClick={scrollToTop} to="/Feedback"><li style={{fontSize:'15px'}}>Feedbacks</li></Link> */}
          </ul>
        </div>
        <div className="col-md-4 col-12">         
        <iframe
                src={map}
                title="Google Maps Location"
                className="mb-4 mb-md-0"
                style={{ width: "100%", height: "100%", border: "none" }}
              ></iframe>
        </div>
      </div>

    </div>
    </div>
    <div class="row justify-content-center p-3" style={{background:'#AEC4E6'}}>
                &copy; Copyright <strong><span>&nbsp;<a href="https://upskilll.com/">UpSkill Training Institute</a></span></strong>. All Rights Reserved
            </div>  
  </>
  );
}

export default Footer;
