import React, {useState} from "react";
import "../css/style.css";
// import img3 from "../img/b1.png"
import img2 from "../img/Javaimg3.webp"
import { placement } from "../../dummydata";
import certficate from "../img/certificate.png"
import Accordion from 'react-bootstrap/Accordion';
import emailjs from "emailjs-com";

const Sjava = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_iae8p4o', 'template_wdlflua', e.target, 'ss92jwsjiWWlkjV1I')
      .then(res => {
        console.log(res);
        if (res) {
           window.location.reload();
        }
      },[])
      .catch(err => console.log(err));
  }


  const [activeKey, setActiveKey] = useState("0");

  const handleSelect = (key) => {
    setActiveKey(key === activeKey ? null : key);
  };

  const [expandedColumn, setExpandedColumn] = useState(null);

  // Toggle function to expand or collapse columns
  const toggleColumn = (column) => {
    setExpandedColumn(expandedColumn === column ? null : column);
  };

  
  return (

    <>
    <div className="row" style={{background: 'linear-gradient(to right, #EBF4F5, #B5C6E0)' }}>
    <div className="container">
    <div className="row" > 
            <div className="col-md-7 col-6 ">
            <h1 className="head" style={{ color:'#0193CC'}}><b>Java Full Stack Development Course in Pune 
with 100% Placement Assistance
</b></h1>
            </div>
            <div className="col-md-5 col-6">
            <img className="" src={img2} alt="img" style={{width:'110%', alignItems:'right'}} />
              </div>          
        
      </div>
    </div>
    </div>
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-md-8 col-12" >
        {/* <h1 className="mb-2" style={{ color: '#F17C00' }}>Java Full Stack Development Course </h1> */}

<h2 className="mb-3"style={{ color: '#000', fontSize: '33px' }}><b>Course Overview</b></h2>
<p>
  Embark on a transformative journey into the dynamic realm of Full Stack Development with Upskill IT Training & Placement. Our Full Stack Development course is meticulously designed to equip you with a holistic skill set, covering both front-end and back-end technologies essential for creating robust, end-to-end web applications.
</p>

<h2 className="mt-4 mb-3" style={{ color: '#000', fontSize: '33px' }}><b>What is Full stack development course?</b></h2>
          <p style={{ textAlign: 'justify' }}>
            Our comprehensive program covers the entire stack, from front-end to back-end technologies, equipping you with the skills needed to design, build, and deploy robust web applications. Delve into <strong>HTML, CSS, JavaScript,</strong> and popular frameworks for crafting engaging user interfaces. Explore server-side scripting, databases, and server deployment to master the back-end.
          </p>
        </div> 
        <div className="col-md-4 rounded bg-white p-4 card shadow" >
                    <form onSubmit={sendEmail}>
                        <label for="Name" >Your Name <span style={{color:'red'}}>*</span></label>
                        <input name="name" type="text" className="form-control mb-2" placeholder="Enter Name" required/>
                        <label for="Phone" >Phone Number <span style={{color:'red'}}>*</span></label>
                        <input name="phone" type="text" className="form-control mb-2" placeholder="Phone No" maxLength="10" minLength="10" onKeyPress={(event) => isNaN(event.target.value + String.fromCharCode(event.which)) && event.preventDefault()} required />
                        <label for="Qualification" >Email<span style={{color:'red'}}>*</span></label>
                        <input type="text" name="email" className="form-control mb-3" placeholder="Email" required/>
                        <label for="Qualification" >Qualification<span style={{color:'red'}}>*</span></label>
                        <input name="qualification" type="text" className="form-control mb-2" placeholder="Qualification" required/>
                        <label for="Year of Passing" >Year of Passing<span style={{color:'red'}}>*</span></label>
                        <input name="yop" type="text" className="form-control mb-2" placeholder="Year of Passing" required/>
                        <input type="hidden" name="course" value="Java Full Stack Development" />
                        {/* <label for="Nearest Traning Hub" >Nearest Traning Hub<span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control" placeholder="Nearest Traning Hub" /> */}
                        <center><button type="submit" className="btn text-white mt-2" style={{background: '#F07D00', borderRadius:'22px', border:'none'}}><b>Request a Callback</b><i className='fa fa-long-arrow-alt-right'></i></button></center>
                    </form>
                </div>
<div className="col-md-12 col-12 pt-5 text-center">
<h2 className="mt-4 " style={{ color: '#000', fontSize: '33px' }}><b>Our Placement Process</b></h2>
<p className="mb-3">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
 <div className="row ">
{placement.map((val, index)=>(
   <div className="col-6 col-md-3 pt-4" key={index} >
   <div className="card shadow p-3 d-flex justify-content-center pcard">
       <img src={val.icon} alt="icon" style={{width:`${val.width}`, margin:'auto'}}/>
       <p>{val.name}</p>
   </div>
 </div>
))}  
 </div>

</div>
<div className="col-md-12 col-12 mt-5">
  <h2 className="mt-4 mb-3 text-center" style={{ color: '#F17C00', fontSize: '2.5rem', fontWeight: 'bold' }}>
    Key Features
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    {[
      {
        number: 1,
        title: 'Comprehensive Curriculum',
        description: 'Delve into a well-structured curriculum that covers front-end technologies like HTML, CSS, JavaScript, and popular frameworks such as React. Simultaneously, explore back-end technologies including databases like MySQL.'
      },
      {
        number: 2,
        title: 'Hands-On Projects',
        description: 'Apply theoretical knowledge in real-world scenarios through hands-on projects. Build interactive and responsive web applications, gaining practical experience that enhances your problem-solving abilities.'
      },
      {
        number: 3,
        title: 'Expert Instructors',
        description: 'Learn from industry-seasoned instructors who bring a wealth of experience to the classroom. Benefit from their insights and guidance as you navigate the intricacies of Full Stack Development.'
      },
      {
        number: 4,
        title: 'Industry-Relevant Tools',
        description: 'Acquire proficiency in using industry-standard tools and technologies such as Git, RESTful APIs, and deployment platforms, ensuring you are well-versed in the tools employed by top tech companies.'
      },
      {
        number: 5,
        title: 'Collaborative Learning',
        description: 'Engage in collaborative learning environments, fostering teamwork and communication skills crucial for success in professional settings.'
      },
      {
        number: 6,
        title: 'Job-Oriented Training',
        description: 'Tailored to meet the demands of the job market, our Full Stack Development course includes practical insights into current industry trends and best practices, preparing you for a successful career in web development.'
      }
    ].map((feature) => (
      <li key={feature.number} style={{ marginBottom: '1.5rem', display: 'flex', alignItems: 'flex-start' }}>
        <div style={{
          width: '2rem',
          height: '2rem',
          // borderRadius: '45px',
          // backgroundColor: '#F17C00',
          color: '#000',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.2rem',
          fontWeight: 'bold',
          marginRight: '1rem'
        }}>
          {feature.number}.
        </div>
        <div>
          <b>{feature.title}:</b> 
          <p>{feature.description}</p>
        </div>
      </li>
    ))}
  </ul>
</div>


<div className="col-12 col-md-12">
  <div className="row justify-content-center">
  <h2 className="mt-4 " style={{ color: '#F17C00', fontSize: '33px' }}><b>Full Stack Java Exams & Certification</b></h2>
  <p className="mb-4">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
    <div className="col-12 col-md-8 pt-2">
      <p>
      UpSkill Certification is designed to boost your expertise and set the stage for a thriving career as a Full Stack Developer. Our program combines in-depth theoretical knowledge with hands-on practical sessions. Additionally, we emphasize the development of key skills necessary for success in a rapidly evolving HR landscape. With our guidance, you'll be well-prepared to meet the demands of both technical and managerial roles. Join us to transform your career trajectory with confidence.
      </p>
    </div>
    <div className="col-12 col-md-4">
        <img src={certficate} alt="certficate" style={{width:'100%'}}/>
    </div>
  </div>
</div>


<div className="col-12 col-md-12 pt-5">
      <div className="row ">
        <div className="col-md-12 col-12  text-center">
        <h2 className="mt-4" style={{ color: '#F17C00', fontSize: '33px' }}>
          <b>Frequently Asked Questions</b>
        </h2>
        <p className="mb-4">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
        </div>

        <div className="coll-12 col-md-12">
      <Accordion activeKey={activeKey} onSelect={handleSelect}>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="accordion-header-custom">
            <div className="row">
              <div className="col-md-11 col-11">
              Is it simple to learn Java full stack development?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "0" ? "open" : ""}`}>
              {activeKey === "0" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>            
          </Accordion.Header>
          <Accordion.Body className="accordion-body-custom">
            The Java Server "Full Stack Java developer" technology is a quick and easy approach to create dynamic and static web content. 
            It is a fantastic capacity to design components to improve web apps and our career prospects as IT professionals.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="accordion-header-custom">
          <div className="row">
              <div className="col-md-11 col-11">
              Are full stack developers paid more?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "1" ? "open" : ""}`}>
              {activeKey === "1" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>            
          </Accordion.Header>
          <Accordion.Body className="accordion-body-custom">
            Full stack developer is a bogus word. When you compare incomes and tasks, you will end up earning the same or even less than you are now. 
            According to Paysa, a backend developer earns $90k, a frontend developer earns $88k, and a fullstack developer earns $76k.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className="accordion-header-custom">
            <div className="row">
              <div className="col-md-11"> What is included in Java full-stack?</div>
              <div className="col-md-1 text-right">            
                <span className={`accordion-icon ${activeKey === "2" ? "open" : ""}`}>
              {activeKey === "2" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span></div>
            </div>  
          </Accordion.Header>
          <Accordion.Body className="accordion-body-custom">
            A full-stack developer is defined as "a candidate who can design both client and server applications." 
            When a developer works on full-stack, it means that he or she is responsible for all front-end, back-end, database, and integration activities.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className="accordion-header-custom">
          <div className="row">
              <div className="col-md-11 col-11">
              Which is better: a Java developer or a full stack developer?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "3" ? "open" : ""}`}>
              {activeKey === "3" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>                
              </div>
            </div>            
          </Accordion.Header>
          <Accordion.Body className="accordion-body-custom">
            Actually, the two most popular full-stack programming technologies that all experts advocate are Java and Python. 
            However, Java is one of the most widely used high-level programming languages for server-side development. I must emphasize why JAVA is more significant.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header className="accordion-header-custom">
          <div className="row">
              <div className="col-md-11 col-11">
              Which is better, MEAN stack or Java full stack?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "4" ? "open" : ""}`}>
              {activeKey === "4" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>            
          </Accordion.Header>
          <Accordion.Body className="accordion-body-custom">
            While MEAN stack professionals have a stronger grasp on JavaScript-related projects, full-stack developers have more understanding. 
            As a result, businesses benefit from hiring both of them.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
      </div>
    </div>


<div className="col-12 mt-5">
  <h2 className="mt-4 mb-4 text-center" style={{ color: '#000', fontSize: '2.5rem', fontWeight: 'bold' }}>
    Course Syllabus
  </h2>
  <div className="table-responsive pb-5">
      <table className="table table-bordered table-striped">
        <thead>
          <tr style={{ backgroundColor: '#F17C00', color: '#fff' }}>
            <th className="text-center" colSpan="5">Core Java Topics</th>
          </tr>
        </thead>
        <tbody activeKey={activeKey} onSelect={handleSelect}>
          <tr>
            <td>
              <button onClick={() => toggleColumn('column1')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Java Basics    
                <span className={`accordion-icon ${expandedColumn === 'column1' ? "open" : ""}`}>
                &nbsp;{expandedColumn === 'column1' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
            </b>
              </button>
              {expandedColumn === 'column1' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Java Syntax</li>
                  <li>Java Output</li>
                  <li>Java Comments</li>
                  <li>Java Variables</li>
                  <li>Java Data Types</li>
                  <li>Java Type Casting</li>
                  <li>Java Operators</li>
                  <li>Java Strings</li>
                  <li>Java Math</li>
                  <li>Java Booleans</li>
                  <li>Java If...Else</li>
                  <li>Java Switch</li>
                  <li>Java While Loop</li>
                  <li>Java For Loop</li>
                  <li>Java Break/Continue</li>
                  <li>Java Arrays</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleColumn('column2')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Java Methods  <span className={`accordion-icon ${expandedColumn === 'column2' ? "open" : ""}`}>
                &nbsp;{expandedColumn === 'column2' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span></b>
              </button>
              {expandedColumn === 'column2' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Java Method Parameters</li>
                  <li>Java Method Overloading</li>
                  <li>Java Scope</li>
                  <li>Java Recursion</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleColumn('column3')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Java Classes
                <span className={`accordion-icon ${expandedColumn === 'column3' ? "open" : ""}`}>
                &nbsp;{expandedColumn === 'column3' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'column3' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Java OOP</li>
                  <li>Java Classes/Objects</li>
                  <li>Java Class Attributes</li>
                  <li>Java Class Methods</li>
                  <li>Java Constructors</li>
                  <li>Java Modifiers</li>
                  <li>Java Encapsulation</li>
                  <li>Java Packages / API</li>
                  <li>Java Inheritance</li>
                  <li>Java Polymorphism</li>
                  <li>Java Inner Classes</li>
                  <li>Java Abstraction</li>
                  <li>Java Interface</li>
                  <li>Java Enums</li>
                  <li>Java User Input</li>
                  <li>Java Date</li>
                  <li>Java ArrayList</li>
                  <li>Java LinkedList</li>
                  <li>Java List Sorting</li>
                  <li>Java HashMap</li>
                  <li>Java HashSet</li>
                  <li>Java Iterator</li>
                  <li>Java Wrapper Classes</li>
                  <li>Java Exception</li>
                  <li>Java RegEx</li>
                  <li>Multithreading</li>
                  <li>Java Lambda</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleColumn('column4')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Java File Handling
                <span className={`accordion-icon ${expandedColumn === 'column4' ? "open" : ""}`}>
                &nbsp;{expandedColumn === 'column4' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'column4' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Java Files</li>
                  <li>Java Create/Write Files</li>
                  <li>Java Read Files</li>
                  <li>Java Delete Files</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleColumn('column5')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Java Reference
                <span className={`accordion-icon ${expandedColumn === 'column5' ? "open" : ""}`}>
                &nbsp;{expandedColumn === 'column5' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'column5' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Java Keywords</li>
                  <li>Java String Methods</li>
                  <li>Java Math Methods</li>
                  <li>Java Output Methods</li>
                  <li>Java Arrays Methods</li>
                  <li>Java ArrayList Methods</li>
                  <li>Java LinkedList Methods</li>
                  <li>Java HashMap Methods</li>
                </ul>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="table-responsive pb-5">
      <table className="table table-bordered table-striped">
        <thead>
          <tr style={{ backgroundColor: '#F17C00', color: '#fff' }}>
            <th className="text-center" colSpan="4">Advance Java</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <button onClick={() => toggleColumn('jdbc')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>JDBC
                <span className={`accordion-icon ${expandedColumn === 'jdbc' ? "open" : ""}`}>
                &nbsp;{expandedColumn === 'jdbc' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'jdbc' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Java Database Connectivity is an application programming interface for the Java programming language which defines how a client may access a database.</li>
                  <li>It is a Java-based data access technology used for Java database connectivity.</li>
                  <li>It is part of the Java Standard Edition platform, from Oracle Corporation.</li>
                  <li>JDBC (Java Database Connectivity): Inserting Data</li>
                  <li>JDBC (Java Database Connectivity): Retrieving Data</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleColumn('servlets')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Servlets
                <span className={`accordion-icon ${expandedColumn === 'column1' ? "open" : ""}`}>
                &nbsp; {expandedColumn === 'column1' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'servlets' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>A servlet is a Java programming language class that is used to extend the capabilities of servers that host applications accessed by means of a request-response programming model.</li>
                  <li>Although servlets can respond to any type of request, they are commonly used to extend the applications hosted by web servers.</li>
                  <li>What is Web Application? What is Servlet? What are GET and POST?</li>
                  <li>Servlet Lifecycle (init, service, destroy) Basic programs.</li>
                  <li>Get data from HTML in servlet. getParameter(), parseInt(), PrintWriter.</li>
                  <li>Extra Programs on Servlet</li>
                  <li>RequestDispatcher, Session, Cookies.</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleColumn('javaBeans')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Java Beans
                <span className={`accordion-icon ${expandedColumn === 'column1' ? "open" : ""}`}>
                 &nbsp; {expandedColumn === 'column1' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'javaBeans' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>JavaBeans is a portable, platform-independent model written in Java Programming Language.</li>
                  <li>Its components are referred to as beans. In simple terms, JavaBeans are classes which encapsulate several objects into a single object.</li>
                  <li>It helps in accessing these objects from multiple places.</li>
                  <li>JSTL (Core tags, Formatting Tags, Function Tags)</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleColumn('jsp')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>JSP
                <span className={`accordion-icon ${expandedColumn === 'column1' ? "open" : ""}`}>
                &nbsp; {expandedColumn === 'column1' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'jsp' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>In Java, JSP stands for Jakarta Server Pages (formerly JavaServer Pages).</li>
                  <li>It is a server-side technology which is used for creating web applications.</li>
                  <li>It is used to create dynamic web content.</li>
                  <li>JSP consists of both HTML tags and JSP tags.</li>
                </ul>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="table-responsive pb-5">
      <table className="table table-bordered table-striped">
        <thead>
          <tr style={{ backgroundColor: '#F17C00', color: '#fff' }}>
            <th className="text-center" colSpan="3">Frameworks</th>            
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <button onClick={() => toggleColumn('spring')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Spring
                <span className={`accordion-icon ${expandedColumn === 'spring' ? "open" : ""}`}>
                &nbsp; {expandedColumn === 'spring' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'spring' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Inversion of control</li>
                  <li>Dependency injection</li>
                  <li>Aspect oriented programming</li>
                  <li>Autowire</li>
                  <li>Spring bean</li>
                  <li>Spring mvc</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleColumn('springBoot')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Spring Boot
                <span className={`accordion-icon ${expandedColumn === 'springBoot' ? "open" : ""}`}>
                &nbsp; {expandedColumn === 'springBoot' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'springBoot' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Spring Boot makes it easy to create stand-alone, production-grade Spring based Applications that you can "just run".</li>
                  <li>Opinionated view of the Spring platform and third-party libraries for easy startup.</li>
                  <li>Minimal Spring configuration needed.</li>
                  <li>What are Frameworks in Java? What is the use of Frameworks in Java? Spring Introduction.</li>
                  <li>Simple project in Spring.</li>
                  <li>What are Frameworks in Java? What is the use of Frameworks in Java? Spring Introduction.</li>
                  <li>Simple project in Spring.</li>
                  <li>What are Frameworks in Java? What is the use of Frameworks in Java? Spring Introduction.</li>
                  <li>Simple project in Spring.</li>
                  <li>What are Frameworks in Java? What is the use of Frameworks in Java? Spring Introduction.</li>
                  <li>Simple project in Spring.</li>
                  <li>What are Frameworks in Java? What is the use of Frameworks in Java?</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleColumn('hibernate')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Hibernate
                <span className={`accordion-icon ${expandedColumn === 'hibernate' ? "open" : ""}`}>
                &nbsp;{expandedColumn === 'hibernate' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'hibernate' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Hibernate is a Java framework that simplifies the development of Java applications to interact with the database.</li>
                  <li>Open source, lightweight, ORM (Object Relational Mapping) tool.</li>
                  <li>Implements JPA (Java Persistence API) specifications for data persistence.</li>
                  <li>How Hibernate works with XML.</li>
                  <li>Hibernate Dialects.</li>
                  <li>Hibernate using Annotations.</li>
                  <li>What is Hibernate? Use of Hibernate.</li>
                  <li>First program of Hibernate.</li>
                  <li>Aggregate Functions in Hibernate.</li>
                  <li>Introduction to Relationship Mappings.</li>
                  <li>One-to-One Mapping.</li>
                  <li>One-to-Many Mapping.</li>
                  <li>Many-to-Many Mapping.</li>
                  <li>CRUD operation in Hibernate.</li>
                </ul>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="table-responsive pb-5">
      <table className="table table-bordered table-striped">
        <thead>
          <tr style={{ backgroundColor: '#F17C00', color: '#fff' }}>
            <th className="text-center" colSpan="2">Database</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <button onClick={() => toggleColumn('mysql')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>MySQL & PLSQL
                <span className={`accordion-icon ${expandedColumn === 'mysql' ? "open" : ""}`}>
                &nbsp;{expandedColumn === 'mysql' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedColumn === 'mysql' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>1. What is Data? What is database? What is DBMS? What is Relational DBMS? What is SQL? What is NoSQL? Characteristics of DBMS (ACID).</li>
                  <li>2. MySQL Workbench installation. How to create a database in MySQL? How to create a table in MySQL?</li>
                  <li>3. SQL Queries: Insert, Select, Update, Delete</li>
                  <li>4. Clauses – where, and, or, not, between, in, like (%, _).</li>
                  <li>5. Aggregate Functions (sum, avg, count, max, min), Character Functions (upper, lower, trim, length), Date Functions (sysdate, now, day(sysdate()), dayname, month, monthname, year, datediff), Time Functions (hours, minutes, seconds)</li>
                  <li>7. Alter Query (Add column, Delete Column, Rename Column, Change datatype of column)</li>
                  <li>8. Constraints: Primary Key, Foreign Key, Check, NotNull, Default</li>
                  <li>9. SubQuery, Joins (Inner Join, LeftOuter Join, RightOuter Join, FullOuter Join, Cross Join)</li>
                  <li>10. Function, Procedure, Trigger</li>
                </ul>
              )}

            </td>
          </tr>
        </tbody>
      </table>
    </div>

</div>
      </div>
    </div>
    </>
  );
}

export default Sjava;
