import React, {useState} from "react";
import "../css/style.css";
import img2 from "../img/b6.png";
import { placement } from "../../dummydata";
import certficate from "../img/certificate.png";
import Accordion from 'react-bootstrap/Accordion';
import emailjs from "emailjs-com";

const Sstesting = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_iae8p4o', 'template_wdlflua', e.target, 'ss92jwsjiWWlkjV1I')
      .then(res => {
        console.log(res);
        if (res) {
           window.location.reload();
        }
      },[])
      .catch(err => console.log(err));
  }

  const [activeKey, setActiveKey] = useState("0");

  const handleSelect = (key) => {
    setActiveKey(key === activeKey ? null : key);
  };

  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  // const [expandedSection, setExpandedSection] = useState(null);

  // const toggleSection = (section) => {
  //   setExpandedSection(expandedSection === section ? null : section);
  // };
  return (
    <>
      <div id="#id" className="row" style={{background: 'linear-gradient(to right, #EBF4F5, #B5C6E0)'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-7">
              <h1 className="head" style={{color: '#0193CC'}}><b>Software Testing Course in Pune with 100% Placement Assistance</b></h1>
            </div>
            <div className="col-md-5 col-5">
              <img className="" src={img2} alt="img" style={{width: '110%', alignItems: 'right'}} />
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-8 col-12">
            <h2 className="mb-3" style={{ color: '#000', fontSize: '33px' }}><b>Course Overview</b></h2>
            <p>
              Embark on a transformative journey into the dynamic realm of Software Testing with Upskill IT Training & Placement. Software testing is the process of finding errors in the developed product. It also checks whether the real outcomes can match expected results, as well as aids in the identification of defects, missing requirements, or gaps.
            </p>
            <h2 className="mt-4 mb-3" style={{ color: '#000', fontSize: '33px' }}><b>What is Software Testing?</b></h2>
            <p style={{ textAlign: 'justify' }}>
              Our comprehensive program covers both Manual and Automation testing methodologies, equipping you with the skills needed to ensure the quality and reliability of software applications. Dive into tools like Selenium, TestNG, Maven, and Cucumber to master the testing process.
            </p>
          </div>
 
          <div className="col-md-4 rounded bg-white p-4 card shadow">
          <form onSubmit={sendEmail}>
                        <label for="Name" >Your Name <span style={{color:'red'}}>*</span></label>
                        <input name="name" type="text" className="form-control mb-2" placeholder="Enter Name" required/>
                        <label for="Phone" >Phone Number <span style={{color:'red'}}>*</span></label>
                        <input name="phone" type="text" className="form-control mb-2" placeholder="Phone No" maxLength="10" minLength="10" onKeyPress={(event) => isNaN(event.target.value + String.fromCharCode(event.which)) && event.preventDefault()} required />
                        <label for="Qualification" >Email<span style={{color:'red'}}>*</span></label>
                        <input type="text" name="email" className="form-control mb-3" placeholder="Email" required/>
                        <label for="Qualification" >Qualification<span style={{color:'red'}}>*</span></label>
                        <input name="qualification" type="text" className="form-control mb-2" placeholder="Qualification" required/>
                        <label for="Year of Passing" >Year of Passing<span style={{color:'red'}}>*</span></label>
                        <input name="yop" type="text" className="form-control mb-2" placeholder="Year of Passing" required/>
                        <input type="hidden" name="course" value="Software Testing Course" />
                        {/* <label for="Nearest Traning Hub" >Nearest Traning Hub<span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control" placeholder="Nearest Traning Hub" /> */}
                        <center><button type="submit" className="btn text-white mt-2" style={{background: '#F07D00', borderRadius:'22px', border:'none'}}><b>Request a Callback</b><i className='fa fa-long-arrow-alt-right'></i></button></center>
                    </form>
          </div>

          <div className="col-md-12 col-12 pt-5 text-center">
            <h2 className="mt-4" style={{ color: '#000', fontSize: '33px' }}><b>Our Placement Process</b></h2>
            <p className="mb-3">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
            <div className="row">
              {placement.map((val, index) => (
                <div className="col-6 col-md-3 pt-4" key={index}>
                  <div className="card shadow p-3 d-flex justify-content-center pcard">
                    <img src={val.icon} alt="icon" style={{width: `${val.width}`, margin: 'auto'}}/>
                    <p>{val.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-md-12 col-12 mt-5">
            <h2 className="mt-4 mb-3 text-center" style={{ color: '#F17C00', fontSize: '2.5rem', fontWeight: 'bold' }}>Key Features</h2>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {[
                {
                  number: 1,
                  title: 'Comprehensive Curriculum',
                  description: 'Delve into a well-structured curriculum that covers Manual and Automation testing with tools like Selenium, TestNG, Maven, Cucumber, etc.'
                },
                {
                  number: 2,
                  title: 'Hands-On Projects',
                  description: 'Apply theoretical knowledge in real-world scenarios through hands-on projects. Build interactive and responsive web applications, gaining practical experience that enhances your problem-solving abilities.'
                },
                {
                  number: 3,
                  title: 'Expert Instructors',
                  description: 'Learn from industry-seasoned instructors who bring a wealth of experience to the classroom. Benefit from their insights and guidance as you navigate the intricacies of Software Testing.'
                },
                {
                  number: 4,
                  title: 'Industry-Relevant Tools',
                  description: 'Acquire proficiency in using industry-standard tools and technologies such as Selenium, TestNG, Maven, and Cucumber, ensuring you are well-versed in the tools employed by top tech companies.'
                },
                {
                  number: 5,
                  title: 'Collaborative Learning',
                  description: 'Engage in collaborative learning environments, fostering teamwork and communication skills crucial for success in professional settings.'
                },
                {
                  number: 6,
                  title: 'Job-Oriented Training',
                  description: 'Tailored to meet the demands of the job market, our Software Testing course includes practical insights into current industry trends and best practices, preparing you for a successful career in software testing.'
                }
              ].map((feature) => (
                <li key={feature.number} style={{ marginBottom: '1.5rem', display: 'flex', alignItems: 'flex-start' }}>
                  <div style={{
                    width: '2rem',
                    height: '2rem',
                    color: '#000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    marginRight: '1rem'
                  }}>
                    {feature.number}.
                  </div>
                  <div>
                    <b>{feature.title}:</b>
                    <p>{feature.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-12 col-md-12">
            <div className="row justify-content-center">
              <h2 className="mt-4" style={{ color: '#F17C00', fontSize: '33px' }}><b>Software Testing Exams & Certification</b></h2>
              <p className="mb-4">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
              <div className="col-12 col-md-8 pt-2">
                <p>
                  UpSkill Certification is designed to boost your expertise and set the stage for a thriving career as a Software Tester. Our program combines in-depth theoretical knowledge with hands-on practical sessions. Additionally, we emphasize the development of key skills necessary for success in a rapidly evolving HR landscape. With our guidance, you'll be well-prepared to meet the demands of both technical and managerial roles. Join us to transform your career trajectory with confidence.
                </p>
              </div>
              <div className="col-12 col-md-4">
                <img src={certficate} alt="certficate" style={{width: '100%'}}/>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 pt-5">
            <div className="row">
              <div className="col-md-12 col-12 text-center">
                <h2 className="mt-4" style={{ color: '#F17C00', fontSize: '33px' }}>
                  <b>Frequently Asked Questions</b>
                </h2>
                <p className="mb-4">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
              </div>

              <div className="col-12 col-md-12">
                <Accordion defaultActiveKey="0" activeKey={activeKey} onSelect={handleSelect}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="accordion-header-custom">
                    <div className="row">
              <div className="col-md-11 col-11">
              Is it simple to learn Software Testing?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "0" ? "open" : ""}`}>
              {activeKey === "0" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>  
                      </Accordion.Header>
                    <Accordion.Body className="accordion-body-custom">
                      The process of learning software testing involves understanding various testing methodologies and tools. With structured training, dedication, and practice, anyone can grasp these concepts effectively.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="accordion-header-custom">
                    <div className="row">
              <div className="col-md-11 col-11">
              How long does it take to become proficient in Software Testing?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "1" ? "open" : ""}`}>
              {activeKey === "1" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>  
                     </Accordion.Header>
                    <Accordion.Body className="accordion-body-custom">
                      Proficiency in software testing depends on your prior knowledge, the course's depth, and your practice. Typically, with dedicated training and practical experience, you can become proficient in a few months.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="accordion-header-custom">
                    <div className="row">
              <div className="col-md-11 col-11">
              What career opportunities are available after completing the course?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "2" ? "open" : ""}`}>
              {activeKey === "2" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>  
                     </Accordion.Header>
                    <Accordion.Body className="accordion-body-custom">
                      After completing a software testing course, you can pursue roles such as QA Tester, Test Engineer, or Automation Tester in various industries, including IT, finance, healthcare, and more.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="accordion-header-custom">
                    <div className="row">
              <div className="col-md-11 col-11">
              Are there any certifications available?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "3" ? "open" : ""}`}>
              {activeKey === "3" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>  
                      </Accordion.Header>
                    <Accordion.Body className="accordion-body-custom">
                      Yes, various certifications are available for software testers, such as ISTQB, CSTE, and others. These certifications can enhance your credibility and career prospects in the field.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container">
          <div className="row">
          <div className="table-responsive pb-5">
      <table className="table table-bordered table-striped">
        <thead>
          <tr style={{ backgroundColor: '#F17C00', color: '#fff' }}>
            <th className="text-center" colSpan="4">Automation Testing Topics</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <button 
                onClick={() => toggleSection('Basics of Selenium')} 
                style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}
              >
                <b>Basics of Selenium
                <span className={`accordion-icon ${expandedSection === 'Basics of Selenium' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Basics of Selenium' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Basics of Selenium' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Eclipse configuration</li>
                  <li>Basics of Selenium</li>
                  <li>Locator</li>
                  <li>Dynamic XPATH</li>
                  <li>Synchronizing Test with Wait</li>
                  <li>Handling Web Tables</li>
                  <li>Action Class</li>
                </ul>
              )}
            </td>
            <td>
              <button 
                onClick={() => toggleSection('TestNG')} 
                style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}
              >
                <b>TestNG & Miscellaneous
                <span className={`accordion-icon ${expandedSection === 'TestNG' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'TestNG' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'TestNG' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Selenium Listener</li>
                  <li>Miscellaneous Selenium Scenarios</li>
                  <li>Excel Sheet Operation</li>
                  <li>Properties File</li>
                  <li>TestNG</li>
                  <li>Assertion in TestNG</li>
                  <li>Exploring TestNG xml File</li>
                </ul>
              )}
            </td>
            <td>
              <button 
                onClick={() => toggleSection('Maven')} 
                style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}
              >
                <b>Maven & CI Tools
                <span className={`accordion-icon ${expandedSection === 'Maven' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Maven' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Maven' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Parameterization to Test Cases</li>
                  <li>Listener to TestNG</li>
                  <li>Report Generation in TestNG</li>
                  <li>Maven</li>
                  <li>CI-Continuous Integration – Jenkins</li>
                  <li>Integration Of Maven/ TestNG/Selenium/Jenkins</li>
                </ul>
              )}
            </td>
            <td>
              <button 
                onClick={() => toggleSection('Advanced Topics')} 
                style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}
              >
                <b>Advanced Topics
                <span className={`accordion-icon ${expandedSection === 'Advanced Topics' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Advanced Topics' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Advanced Topics' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Robot class & auto IT</li>
                  <li>Keyword Framework</li>
                  <li>Log4j</li>
                  <li>Page Object Model</li>
                  <li>Selenium Grid</li>
                  <li>Cucumber</li>
                  <li>Tomcat Webserver</li>
                  <li>Email</li>
                </ul>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

          </div>

          <div className="row">
          <div className="table-responsive pb-5">
      <table className="table table-bordered table-striped">
        <thead>
          <tr style={{ backgroundColor: '#F17C00', color: '#fff' }}>
            <th className="text-center" colSpan="4">Manual Testing Syllabus</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <button 
                onClick={() => toggleSection('Module: Software Testing')} 
                style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}
              >
                <b>Module: Software Testing
                <span className={`accordion-icon ${expandedSection === 'Module: Software Testing' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Module: Software Testing' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Module: Software Testing' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>What is testing?</li>
                  <li>Importance of testing</li>
                  <li>Roles and Responsibilities</li>
                  <li>Principles of software testing</li>
                  <li>What is Quality?</li>
                  <li>Differences between Manual and Automation Testing</li>
                  <b>Module: Software Development Life Cycle</b>
                  <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>SDLC Phases</li>
                    <li>Requirements Phase</li>
                    <li>Analysis Phase</li>
                    <li>Design phase</li>
                    <li>Coding Phase</li>
                    <li>Testing phase</li>
                    <li>Delivery and Maintenance Phase</li>
                  </ul>
                </ul>
              )}
            </td>
            <td>
              <button 
                onClick={() => toggleSection('Methodologies')} 
                style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}
              >
                <b>Module: Software Testing Methodologies
                <span className={`accordion-icon ${expandedSection === 'Methodologies' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Methodologies' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Methodologies' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>White Box Testing</li>
                  <li>Black Box Testing</li>
                  <b>Module: Test Case Design Techniques</b>
                  <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>Static Techniques</li>
                    <li>Informal Reviews</li>
                    <li>Walkthrough</li>
                    <li>Technical Reviews</li>
                    <li>Inspection</li>
                    <li>Dynamic Techniques: Structural Techniques</li>
                    <li>Statement Coverage Testing</li>
                    <li>Path Coverage Testing</li>
                    <li>Loop Coverage Testing</li>
                    <li>Black Box Techniques</li>
                    <li>Boundary Value Analysis</li>
                    <li>Equivalence Class Partition</li>
                    <li>State Transition Technique</li>
                    <li>Cause Effective Graph</li>
                    <li>Decision Table</li>
                    <li>Use Case Testing</li>
                    <li>Experienced Based Techniques</li>
                    <li>Error guessing</li>
                    <li>Exploratory testing</li>
                  </ul>
                </ul>
              )}
            </td>
            <td>
              <button 
                onClick={() => toggleSection('Planning')} 
                style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}
              >
                <b>Module: Test Planning
                <span className={`accordion-icon ${expandedSection === 'Planning' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Planning' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Planning' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Object</li>
                  <li>Scope of Testing</li>
                  <li>Schedule</li>
                  <li>Approach</li>
                  <li>Roles & Responsibilities</li>
                  <li>Assumptions</li>
                  <li>Risks & Mitigations</li>
                  <li>Entry & Exit Criteria</li>
                  <li>Test Automation</li>
                  <li>Deliverables</li>
                  <b>Test Cases Design</b>
                  <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>Write Test cases</li>
                    <li>Review Test Cases</li>
                    <li>Test Cases Template</li>
                    <li>Types Of Test Cases</li>
                    <li>Difference between Test scenarios and Test Cases</li>
                  </ul>
                </ul>
              )}
            </td>
            <td>
              <button 
                onClick={() => toggleSection('QA & QC')} 
                style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}
              >
                <b>Module: QA & QC & Testing
                <span className={`accordion-icon ${expandedSection === 'QA & QC' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'QA & QC' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'QA & QC' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>What is Quality Assurance?</li>
                  <li>What is Quality Control?</li>
                  <li>Differences of QA & QC & Testing</li>
                  <li>Test Management with TFS Tool (Learn & Implement)</li>
                  <li>Defect Tracking Tools (Learn & Implement)</li>
                </ul>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Sstesting;
