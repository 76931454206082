import React, {useState} from "react";
import Popup from './Form';
import "../css/courses.css" 
import img1 from "../img/new3.png"
import img2 from "../img/Javaimg3.webp"
import img3 from "../img/jsban1.jpg"
import { Link } from "react-router-dom";

const JavaCourse = () => {
    
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = () => {
      setIsPopupVisible(!isPopupVisible);
    };

    return(
        <>
      <div className="row"> <h1 style={{marginBottom: '50px', position:'absolute', padding:'160px', color:'#E0EBD1'}}><b>Java Full Stack Developer</b></h1>
        <img src={img3} alt="img" width={'100%'} />
      </div>
        <div  className="container pb-5 pt-5">
            <div className="row p-2">
                <div className="col-md-8 p-2">
                    <span style={{color:'#F17C00'}}><b>Become a Certified</b></span>
                    <h2><b>Java Full Stack Developement</b></h2>
                    <p style={{textAlign:'justify'}}>Embark on a transformative journey into the dynamic realm of<strong> Full Stack Development with Upskill IT Training & Placement.</strong> Our Full Stack Development course is meticulously designed to equip you with a holistic skill set, covering both front-end and back-end technologies essential for creating robust, end-to-end web applications.
                    </p>
                    {/* <h2><b>Java Syllabus</b></h2> */}
                    {/* <table style={{textAlign:'justify'}}>
                  <tr>
                     <li>• Java Fundamentals</li>
                     <li>• Data Structure and Algorithms</li>
                     <li>• Multithreading, concurrency, and thread basics</li>
                     <li>• Object-Oriented Concepts</li>
                     <li>• Java Collections Framework</li>
                     <li>• Date type conversion and fundamentals</li>
                     <li>• Array</li>
                     <li>• Garbage Collection</li>
                  </tr>
                </table> */}

                    <div className="row pt-2">
                        <div className="col-lg-2"><button className="btn a1"><b>Frontend</b></button></div>
                        <div className="col-lg-1 " style={{border:'none'}}> <button className="btn aa"><b>+</b></button></div>
                        <div className="col-lg-2 "><button className="btn a1"><b>Backend</b></button></div>
                        <div className="col-lg-1 "  style={{border:'none'}}> <button className="btn aa"><b>=</b></button></div>
                        <div className="col-lg-4 "><button className="btn  a1"><b>Full Stack Developer</b><i className='fa fa-long-arrow-alt-right'></i></button></div>                  
            </div>

                    <div className="row p-1">
                        <div className="col-lg-6"><button onClick={togglePopup} className="btn  a2"><b>Access the demo lecture Now</b><i className='fa fa-long-arrow-alt-right'></i></button></div>
                        <div className="col-lg-6">
  <a href={img1} download>
    <button className="btn a3">
      <b>Download Java Syllabus</b>
      <i className='fa fa-long-arrow-alt-right'></i>
    </button>
  </a>
</div>
              
            </div>
                </div>
                <div className="col-md-4 rounded bg-white p-4 card" style={{height: '420px'}}>
                    <form>
                        <label for="Name" >Your Name <span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control mb-2" placeholder="Enter Name" required/>
                        <label for="Phone" >Phone Number <span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control mb-2" placeholder="Phone No" maxLength="10" minLength="10" onKeyPress={(event) => isNaN(event.target.value + String.fromCharCode(event.which)) && event.preventDefault()} required />
                        <label for="Qualification" >Qualification<span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control mb-2" placeholder="Qualification" required/>
                        <label for="Year of Passing" >Year of Passing<span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control mb-2" placeholder="Year of Passing" required/>
                        {/* <label for="Nearest Traning Hub" >Nearest Traning Hub<span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control" placeholder="Nearest Traning Hub" /> */}
                        <center><button type="submit" className="btn text-white mt-4" style={{background: '#F07D00', borderRadius:'22px', border:'none'}}><b>Request a Callback</b><i className='fa fa-long-arrow-alt-right'></i></button></center>
                    </form>
                </div>
            </div>
            <div className="row pt-5">
                            <h2 style={{color:'#F17C00', borderBottom:'3px solid #F17C00'}}>Key Features</h2>
                            <ul>
                                <li><b>1. Comprehensive Curriculum: </b>Delve into a well-structured curriculum that covers front-end technologies like HTML, CSS, JavaScript, and popular frameworks such as React. Simultaneously, explore back-end technologies including databases like MySQL.
                                </li>
                                <li><b>2. Hands-On Projects: </b> Apply theoretical knowledge in real-world scenarios through hands-on projects. Build interactive and responsive web applications, gaining practical experience that enhances your problem-solving abilities.

                                </li>
                                <li><b>3. Expert Instructors: </b> Learn from industry-seasoned instructors who bring a wealth of experience to the classroom. Benefit from their insights and guidance as you navigate the intricacies of Full Stack Development.

                                </li>
                                <li><b>4. Industry-Relevant Tools: </b>  Acquire proficiency in using industry-standard tools and technologies such as Git, RESTful APIs, and deployment platforms, ensuring you are well-versed in the tools employed by top tech companies.

                                </li>
                                <li><b>5. Collaborative Learning: </b> Engage in collaborative learning environments, fostering teamwork and communication skills crucial for success in professional settings.

                                </li>
                                <li><b>6. Job-Oriented Training: </b>  Tailored to meet the demands of the job market, our Full Stack Development course includes practical insights into current industry trends and best practices, preparing you for a successful career in web development.


                                </li>
                            </ul> 
            </div>
            <div className="row p-5">
                <div className="col-md-12 card p-3">
                    <div className="row">
                        <div className="col-md-4 ">
                            <div className="row">
                            <div className="col-md-3 text-center p-2" >
                            <img src="https://thekiranacademy.com/img/orange-calender-img.svg" alt="ab"/>
                            </div>
                            <div className="col-md-9 p-3 ">
                            <span><b>&nbsp;&nbsp;&nbsp;Course Duration</b></span><br/>
                            <span>&nbsp;&nbsp;&nbsp;3 Months Course Duration</span><br/>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="row">
                        <div className="col-md-3 text-center p-2" >
                            <img src="https://thekiranacademy.com/img/blue-calender-img.svg" alt="ab"/>
                            </div>
                            <div className="col-md-9 p-2 ">
                            <span><b>&nbsp;&nbsp;Upcoming Java Batch
                            &nbsp;&nbsp;Starting on Mon, 1st Apr 24</b></span><br/>
                            <span>&nbsp;&nbsp;Limited seats available</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-3 text-center p-2" >
                            <img src="https://thekiranacademy.com/img/green-calender-img.svg" alt="ab"/>
                            </div>
                            <div className="col-md-9 p-3 ">
                            <span><b>&nbsp;&nbsp;&nbsp;Placement Assistance</b></span><br/>
                            <span>&nbsp;&nbsp;&nbsp;Get 100% Job Assistance</span><br/>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-5">
                    <div className="row px-5">
                    <div className="col-12 col-lg-6"><button className="btn  a5"><b>View Java Tutorial</b></button></div>
                    <div className="col-12 col-lg-6"><button className="btn a6"><b>Claim 100% Scholarsip</b></button></div> 
                    </div>                                        
            </div>
            </div>
            <div className="row">
            {/* <div className="col-md-12"><span style={{color:'#F17C00', fontSize:'33px'}}><b>What is Full stack development course ?</b></span></div> */}
            <div className="col-md-12"><span style={{color:'#000', fontSize:'33px'}}><b>What is Full stack development course ?</b></span></div>           
            <div className="col-md-12"><span style={{color:'#000', fontSize:'33px'}}><p style={{textAlign:'justify'}}>Our comprehensive program covers the entire stack, from front-end to back-end technologies, equipping you with the skills needed to design, build, and deploy robust web applications. Delve into <strong>HTML, CSS, JavaScript,</strong> and popular frameworks for crafting engaging user interfaces. Explore server-side scripting, databases, and server deployment to master the back-end. 

                                                                                                                     Our hands-on approach, coupled with real-world projects, ensures you gain practical experience, making you job-ready.
                                                                                                                     Front End  +  Back End  = Full Stack Developer  (Image)
</p></span></div>         
             </div>
            <div className="row pt-5">
                <div className="col-md-12 text-center "><h2>JAVA Certification Programme Highlights</h2></div>
                <div className="col-12 col-md-6 p-5">
                        <div className="row shadow rounded p-3" style={{height:'110px'}}>
                        <div className="col-md-2 ">
                            <img style={{width:'130%'}} src="https://thekiranacademy.com/img/details-img4.svg" alt="imw"/>
                        </div>
                        <div className="col-md-9 pt-3">
                            
                            <span style={{fontSize:'22px'}}><b>25 Hrs Online Session</b></span>
                        </div>
                    </div>
                </div>
                <div className=" col-12 col-md-6 p-5">
                <div className="row shadow rounded p-3" style={{height:'110px'}}>
                        <div className="col-md-2 ">
                            <img style={{width:'130%'}} src="https://thekiranacademy.com/img/details-img5.svg" alt="imw"/>
                        </div>
                        <div className="col-md-9 pt-1">
                            
                            <span style={{fontSize:'22px'}}><b>Training as per JAVA Certification Guide</b></span>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 col-md-6 px-5">
                        <div className="row shadow rounded p-3" style={{height:'110px'}}>
                        <div className="col-md-2 ">
                            <img style={{width:'130%'}} src="https://thekiranacademy.com/img/details-img6.svg" alt="imw"/>
                        </div>
                        <div className="col-md-9 pt-3">
                            
                            <span style={{fontSize:'22px'}}><b>3 Projects, 1 Case Studies</b></span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 px-5">
                <div className="row shadow rounded p-3" style={{height:'110px'}}>
                        <div className="col-md-2 ">
                            <img style={{width:'130%'}} src="https://thekiranacademy.com/img/details-img7.svg" alt="imw"/>
                        </div>
                        <div className="col-md-9 pt-1">
                            
                            <span style={{fontSize:'22px'}}><b>Certification after Course Completion</b></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-md-6">
                    <img src={img2} alt="img" style={{width: '100%'}}/>
                </div>
                <div className="col-md-6 liul">
                    <ul>
                        <li> <h2>Hands-on Practice Covered</h2></li>  
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Core Java</li>
                        {/* <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Java Methods</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Java Classes</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Java File Handling</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Java Reference</li>                      */}
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Advance Java- JDBC, Servlets, Java Beans, JSP</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Frameworks- Hibernate, Spring Boot, Spring</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Database- MySQL/PLSQL</li>
                        
                        <li><Link to='/Sjava'><button className="btn a7">View Syllabus<i className='fa fa-long-arrow-alt-right'></i></button></Link></li>
                    </ul>
                </div>
            </div>

           
        </div>
        <div className="container-fluid" >
            <div className="row" style={{background:'#80ccff' }}>
            <div className="container">
                <div className="row p-3 liul">
         <div className="col-md-12 text-center p-3"><h2>Our Java Training Program | Trainer Profile</h2></div>
         <div className="col-md-12 "><p style={{textAlign:'justify'}}>
         Though you have joined online Java classes or classroom training for Java batch, we have in-house trainers with a strong profile of at least more than 7+ years of experience in Developing JAVA applications, International Project Handling, having knowledge of modern tools and techniques to execute JAVA Coding. The Trainer who executes java fresher to advance batch online or in the classroom training must have the following highlights of their profile:
             </p></div>
             <ul className="">
                 <li></li>
                 <li></li>
                 {/* <li> <h2>Hands-on Practice Covered</h2></li>                        */}
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>7+ years of Java and project execution experienced</li>
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>Having a record of the trained student list of more than 1500 in a year</li>
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>Ex-MNC or Indian MNC faculty to guide you in a more corporate fashion.</li>
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>Java sun-certified / having the highest grade in the past experience</li>
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>Java domain expert to answer and solve your question with real-life examples only</li>
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>Participated in national & international conferences under Java development programs</li>
                 <li><button className="btn a7" style={{width:'50%'}}><b>Meet Our Java Trainer</b><i className='fa fa-long-arrow-alt-right'></i></button></li>
             </ul>
     </div>
            </div>
            </div>

        </div>
         <div className="container  pb-5">
            <div className="row">
            <div className="col-md-12 text-center p-5"><h2>Popular Software Application Programs Written in Java</h2></div>
                <div className="col-md-3 ">
                    <div className="p-4 shadow card">
                    <div className="row ">
                        <img src="https://thekiranacademy.com/img/Spotify.svg" alt="img" />
                    </div>
                    <div className="row pt-3"><h4>Spotify</h4></div>
                    <div className="row ">
                        <p >
                        Spotify: Best music streaming app for enjoying music and podcasts anytime, anywhere.
                        </p>
                    </div>

                    </div>

                </div>
                <div className="col-md-3">
                <div className="p-4 shadow card">
                    <div className="row ">
                        <img src="https://thekiranacademy.com/img/Twitter.svg" alt="img" />
                    </div>
                    <div className="row pt-3"><h4>Twitter</h4></div>
                    <div className="row ">
                        <p >
                        Twitter: Popular social media app for users to tweet their thoughts globally.<br/><br/>
                        </p>
                    </div>
                </div>
                </div>
                <div className="col-md-3">
                <div className="p-4 shadow card">
                    <div className="row ">
                        <img src="https://thekiranacademy.com/img/Operamini.svg" alt="img" />
                    </div>
                    <div className="row pt-3"><h4>Opera Mini</h4></div>
                    <div className="row ">
                        <p >
                        Opera Mini: Top mobile web browser offering excellent online browsing experience.<br/><br/>
                        </p>
                    </div>
                </div>
                </div>
                <div className="col-md-3">
                <div className="p-4 shadow card">
                    <div className="row ">
                        <img src="https://thekiranacademy.com/img/Nimbuzz.svg" alt="img" />
                    </div>
                    <div className="row pt-3"><h4>Nimbuzz Messenger</h4></div>
                    <div className="row ">
                        <p >
                        Nimbuzz: Famous for instant messaging with an API enhancing user chat experience.
                        </p>
                    </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center pt-3">
            <button onClick={togglePopup} className="btn a8" style={{width:'50%'}}><b>Enroll Java course and Build your Own Application</b><i className='fa fa-long-arrow-alt-right'></i></button>
            {isPopupVisible && <Popup handleClose={togglePopup} />}
            </div>
         </div>
     </>
    )
}

export default JavaCourse