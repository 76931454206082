import React from "react"
// import Back from "../common/back/Back"
import CoursesCard from "./CoursesCard"
// import OnlineCourses from "./OnlineCourses"
// import course from "../img/cources.jpg"
// import AboutCard from "./AboutCard"
import "../css/style.css"
import bn from "../img/cr1.png"; 

const CourseHome = () => {
  return (
    <>
              <div className="row" style={{background: 'linear-gradient(to right, #EBF4F5, #B5C6E0)' }}>
        <div className="container">
        <div className="row" > 
            <div className="col-md-7 col-6 p-5">
            <h1 className="head" style={{ color:'#0193CC'}}><b>
              Courses
</b></h1>
            </div>
            <div className="col-md-5 col-6">
            <img className="" src={bn} alt="img" style={{width:'110%', alignItems:'right'}} />
              </div>          
        
      </div>
        </div>
    </div>
      <CoursesCard />
      {/* <AboutCard /> */}
      {/* <OnlineCourses /> */}
    </>
  )
}

export default CourseHome