// src/components/PrivacyPolicy.js

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Privacy Policy</h1>
      {/* <p className="text-center"><strong>Last Updated: [Date]</strong></p> */}

      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to UpSkill Institute! We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our website and services.</p>

        <h2>2. Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li><strong>Personal Information:</strong> Details like your name, email address, and contact information when you register or interact with us.</li>
          <li><strong>Usage Data:</strong> Information about how you use our website, including your IP address and browser type.</li>
          <li><strong>Payment Information:</strong> Details related to payments for our services, such as credit card information.</li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>We use your information to:</p>
        <ul>
          <li>Provide and improve our services.</li>
          <li>Communicate with you about updates and offers.</li>
          <li>Process payments and manage your account.</li>
        </ul>

        <h2>4. Data Security</h2>
        <p>We implement security measures to protect your data from unauthorized access or disclosure. However, no method of transmission over the internet is completely secure.</p>

        <h2>5. Your Rights</h2>
        <p>You have the right to access, correct, or delete your personal information. You can contact us to exercise these rights.</p>

        <h2>6. Changes to This Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website.</p>

        <h2>7. Contact Us</h2>
        <p><strong>UpSkill Institute</strong><br />
           Email: enquiry@upskilll.com<br />
           Phone: +91 8484833677<br />
           Address: B Junction 103, 1st Floor, Near Karve Statue, Next to Kothrud Post Office, Kothrud, Pune-411038</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
