import React from "react"
// import AboutCard from "./AboutCard"
// import Hblog from "./Hblog"
// import HAbout from "./HAbout"
import CoursesCard from "./CoursesCard"
import Hero from "./Hero"
import Hprice from "./Hprice"
import Testimonal from "./Testimonal"
import Awrapper from "./Awrapper"; 
// import HContent from "./HContnet"
// import Slider from "./Slider"

const Home = () => {
  return (
    <div >
      <Hero />
      {/* <Slider/>
      <HContent/> */}
      <CoursesCard/>
      {/* <AboutCard /> */}
      <Awrapper />
      {/* <HAbout /> */}
      <Testimonal />
      {/* <Hblog /> */}
      <Hprice />
    </div>
  )
}

export default Home