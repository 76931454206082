import React, {useState} from "react";
import "../css/style.css";
import img2 from "../img/b2.png"
import { placement } from "../../dummydata";
import certificate from "../img/certificate.png";
import Accordion from 'react-bootstrap/Accordion';
import emailjs from "emailjs-com";

const Spython = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_iae8p4o', 'template_wdlflua', e.target, 'ss92jwsjiWWlkjV1I')
      .then(res => {
        console.log(res);
        if (res) {
           window.location.reload();
        }
      },[])
      .catch(err => console.log(err));
  }

  const [activeKey, setActiveKey] = useState("0");

  const handleSelect = (key) => {
    setActiveKey(key === activeKey ? null : key);
  };


  const [expandedSection, setExpandedSection] = useState(null);

  // Toggle function to expand or collapse sections
  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  // const [expandedSection, setExpandedSection] = useState(null);

  // const toggleSection = (section) => {
  //   setExpandedSection(expandedSection === section ? null : section);
  // };
  return (
    <>
    <div className="row" style={{background: 'linear-gradient(to right, #EBF4F5, #B5C6E0)' }}>
      <div className="container">
        <div className="row">  
          <div className="col-md-7 col-7">
            <h1 className="head" style={{ color:'#0193CC'}}><b>Python Full Stack Development Course in Pune with 100% Placement Assistance</b></h1>
          </div>
          <div className="col-md-5 col-5">
            <img src={img2} alt="Python Course" style={{width:'110%', alignItems:'right'}} />
          </div>          
        </div>
      </div>
    </div>
    
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-md-8 col-12">
          <h2 className="mb-3" style={{ color: '#000', fontSize: '33px' }}><b>Course Overview</b></h2>
          <p>
            Python is a high-level, general-purpose programming language. Its design philosophy emphasizes code readability with the use of significant indentation. Python is dynamically typed and garbage-collected. It supports multiple programming paradigms, including structured, object-oriented, and functional programming.
          </p>

          <h2 className="mt-4 mb-3" style={{ color: '#000', fontSize: '33px' }}><b>What is Full Stack Development Course?</b></h2>
          <p style={{ textAlign: 'justify' }}>
            Our comprehensive program covers the entire stack, from front-end to back-end technologies, equipping you with the skills needed to design, build, and deploy robust web applications. Delve into <strong>HTML, CSS, JavaScript,</strong> and popular frameworks such as Django for crafting engaging user interfaces. Explore server-side scripting, databases, and server deployment to master the back-end.
          </p>
        </div> 
        <div className="col-md-4 rounded bg-white p-4 card shadow">
        <form onSubmit={sendEmail}>
                        <label for="Name" >Your Name <span style={{color:'red'}}>*</span></label>
                        <input name="name" type="text" className="form-control mb-2" placeholder="Enter Name" required/>
                        <label for="Phone" >Phone Number <span style={{color:'red'}}>*</span></label>
                        <input name="phone" type="text" className="form-control mb-2" placeholder="Phone No" maxLength="10" minLength="10" onKeyPress={(event) => isNaN(event.target.value + String.fromCharCode(event.which)) && event.preventDefault()} required />
                        <label for="Qualification" >Email<span style={{color:'red'}}>*</span></label>
                        <input type="text" name="email" className="form-control mb-3" placeholder="Email" required/>
                        <label for="Qualification" >Qualification<span style={{color:'red'}}>*</span></label>
                        <input name="qualification" type="text" className="form-control mb-2" placeholder="Qualification" required/>
                        <label for="Year of Passing" >Year of Passing<span style={{color:'red'}}>*</span></label>
                        <input name="yop" type="text" className="form-control mb-2" placeholder="Year of Passing" required/>
                        <input type="hidden" name="course" value="Python Full Stack Development" />
                        {/* <label for="Nearest Traning Hub" >Nearest Traning Hub<span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control" placeholder="Nearest Traning Hub" /> */}
                        <center><button type="submit" className="btn text-white mt-2" style={{background: '#F07D00', borderRadius:'22px', border:'none'}}><b>Request a Callback</b><i className='fa fa-long-arrow-alt-right'></i></button></center>
                    </form>
        </div>

        <div className="col-md-12 col-12 pt-5 text-center">
          <h2 className="mt-4" style={{ color: '#000', fontSize: '33px' }}><b>Our Placement Process</b></h2>
          <p className="mb-3">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
          <div className="row">
            {placement.map((val, index) => (
              <div className="col-6 col-md-3 pt-4" key={index}>
                <div className="card shadow p-3 d-flex justify-content-center pcard">
                  <img src={val.icon} alt="icon" style={{width:`${val.width}`, margin:'auto'}}/>
                  <p>{val.name}</p>
                </div>
              </div>
            ))}  
          </div>
        </div>

        <div className="col-md-12 col-12 mt-5">
          <h2 className="mt-4 mb-3 text-center" style={{ color: '#F17C00', fontSize: '2.5rem', fontWeight: 'bold' }}>Key Features</h2>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {[
              {
                number: 1,
                title: 'Comprehensive Curriculum',
                description: 'Delve into a well-structured curriculum that covers front-end technologies like HTML, CSS, JavaScript, and popular frameworks such as Django. Simultaneously, explore back-end technologies including databases like PostgreSQL.'
              },
              {
                number: 2,
                title: 'Hands-On Projects',
                description: 'Apply theoretical knowledge in real-world scenarios through hands-on projects. Build interactive and responsive web applications, gaining practical experience that enhances your problem-solving abilities.'
              },
              {
                number: 3,
                title: 'Expert Instructors',
                description: 'Learn from industry-seasoned instructors who bring a wealth of experience to the classroom. Benefit from their insights and guidance as you navigate the intricacies of Full Stack Development.'
              },
              {
                number: 4,
                title: 'Industry-Relevant Tools',
                description: 'Acquire proficiency in using industry-standard tools and technologies such as Git, RESTful APIs, and deployment platforms, ensuring you are well-versed in the tools employed by top tech companies.'
              },
              {
                number: 5,
                title: 'Collaborative Learning',
                description: 'Engage in collaborative learning environments, fostering teamwork and communication skills crucial for success in professional settings.'
              },
              {
                number: 6,
                title: 'Job-Oriented Training',
                description: 'Tailored to meet the demands of the job market, our Full Stack Development course includes practical insights into current industry trends and best practices, preparing you for a successful career in web development.'
              }
            ].map((feature) => (
              <li key={feature.number} style={{ marginBottom: '1.5rem', display: 'flex', alignItems: 'flex-start' }}>
                <div style={{
                  width: '2rem',
                  height: '2rem',
                  color: '#000',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  marginRight: '1rem'
                }}>
                  {feature.number}.
                </div>
                <div>
                  <b>{feature.title}:</b> 
                  <p>{feature.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="col-12 col-md-12">
          <div className="row justify-content-center">
            <h2 className="mt-4" style={{ color: '#F17C00', fontSize: '33px' }}><b>Full Stack Python Exams & Certification</b></h2>
            <p className="mb-4">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
            <div className="col-12 col-md-8 pt-2">
              <p>
                UpSkill Certification is designed to boost your expertise and set the stage for a thriving career as a Full Stack Developer. Our program combines in-depth theoretical knowledge with hands-on practical sessions. Additionally, we emphasize the development of key skills necessary for success in a rapidly evolving HR landscape. With our guidance, you'll be well-prepared to meet the demands of both technical and managerial roles. Join us to transform your career trajectory with confidence.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <img src={certificate} alt="certificate" style={{width:'100%'}}/>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-12 pt-5">
          <div className="row">
            <div className="col-md-12 col-12 text-center">
              <h2 className="mt-4" style={{ color: '#F17C00', fontSize: '33px' }}>
                <b>Frequently Asked Questions</b>
              </h2>
              <p className="mb-4">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
            </div>

            <div className="col-12 col-md-12">
              <Accordion defaultActiveKey="0" activeKey={activeKey} onSelect={handleSelect}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                  <div className="row">
              <div className="col-md-11 col-11">
              What is the duration of the Python Full Stack Development course?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "0" ? "open" : ""}`}>
              {activeKey === "0" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>
                    </Accordion.Header>
                  <Accordion.Body>
                    The course duration is typically 6 months, including both theoretical and practical components. However, the exact duration may vary depending on the specific program and pace of learning.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                  <div className="row">
              <div className="col-md-11 col-11">
              What are the prerequisites for enrolling in this course?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "1" ? "open" : ""}`}>
              {activeKey === "1" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>
                    </Accordion.Header>
                  <Accordion.Body>
                    There are no strict prerequisites for this course. However, a basic understanding of programming concepts and a willingness to learn will be beneficial. Prior knowledge of HTML, CSS, or JavaScript can also be helpful.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                  <div className="row">
              <div className="col-md-11 col-11">
              Will I receive a certificate upon completion of the course?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "2" ? "open" : ""}`}>
              {activeKey === "2" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>
                    </Accordion.Header>
                  <Accordion.Body>
                    Yes, upon successful completion of the course and its assessments, you will receive a certificate that validates your skills and knowledge in Full Stack Python Development.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                  <div className="row">
              <div className="col-md-11 col-11">
              What career support is available after completing the course?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "3" ? "open" : ""}`}>
              {activeKey === "3" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>
                    </Accordion.Header>
                  <Accordion.Body>
                    We offer comprehensive career support including resume building, interview preparation, and job placement assistance. Our goal is to help you secure a position that aligns with your career aspirations.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                  <div className="row">
              <div className="col-md-11 col-11">
              Are there any job placement guarantees?
              </div>
              <div className="col-md-1 col-1 text-right">
              <span className={`accordion-icon ${activeKey === "4" ? "open" : ""}`}>
              {activeKey === "4" ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </span>
              </div>
            </div>
                    </Accordion.Header>
                  <Accordion.Body>
                    While we cannot guarantee specific job placements, we provide extensive support and resources to enhance your employability. Our focus is on preparing you with the skills and knowledge needed to succeed in the job market.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
      <div className="table-responsive pb-5">
      <table className="table table-bordered table-striped">
        <thead >
          <tr style={{ backgroundColor: '#F17C00', color: '#fff' }}>
            <th className="text-center" colSpan="5">Python Course Topics</th>
          </tr>
        </thead>
        <tbody >
          <tr>
            <td>
              <button onClick={() => toggleSection('Basic Python')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Basic Python
                <span className={`accordion-icon ${expandedSection === 'Basic Python' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Basic Python' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Basic Python' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Python Introduction</li>
                  <li>Python Get Started</li>
                  <li>Python Syntax</li>
                  <li>Python Comments</li>
                  <li>Python Variables</li>
                  <li>Python Data Types</li>
                  <li>Python Numbers</li>
                  <li>Python Casting</li>
                  <li>Python Strings</li>
                  <li>Python Booleans</li>
                  <li>Python Operators</li>
                  <li>Python Lists</li>
                  <li>Python Tuples</li>
                  <li>Python Sets</li>
                  <li>Python Dictionaries</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleSection('Control Flow')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Control Flow
                <span className={`accordion-icon ${expandedSection === 'Control Flow' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Control Flow' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Control Flow' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Python If...Else</li>
                  <li>Python While Loops</li>
                  <li>Python For Loops</li>
                  <li>Python Functions</li>
                  <li>Python Lambda</li>
                  <li>Python Arrays</li>
                  <li>Python Classes/Objects</li>
                  <li>Python Inheritance</li>
                  <li>Python Iterators</li>
                  <li>Python Polymorphism</li>
                  <li>Python Scope</li>
                  <li>Python Modules</li>
                  <li>Python Dates</li>
                  <li>Python Math</li>
                  <li>Python JSON</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleSection('Advanced Topics')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Advanced Topics
                <span className={`accordion-icon ${expandedSection === 'Advanced Topics' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Advanced Topics' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Advanced Topics' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Python RegEx</li>
                  <li>Python PIP</li>
                  <li>Python Try...Except</li>
                  <li>Python User Input</li>
                  <li>Python String Formatting</li>
                  <li>File Handling</li>
                  <li>Python File Handling</li>
                  <li>Python Read Files</li>
                  <li>Python Write/Create Files</li> 
                  <li>Python Delete Files</li>
                  <li>Python Modules</li>
                  <li>NumPy Tutorial</li>
                  <li>Pandas Tutorial</li>
                  <li>SciPy Tutorial</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleSection('Visualization')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Data Visualization
                <span className={`accordion-icon ${expandedSection === 'Visualization' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Visualization' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Visualization' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Django Tutorial</li>
                  <li>Python Matplotlib</li>
                  <li>Matplotlib Intro</li>
                  <li>Matplotlib Get Started</li>
                  <li>Matplotlib Pyplot</li>
                  <li>Matplotlib Plotting</li>
                  <li>Matplotlib Markers</li>
                  <li>Matplotlib Line</li>
                  <li>Matplotlib Labels</li>
                  <li>Matplotlib Grid</li>
                  <li>Matplotlib Subplot</li>
                  <li>Matplotlib Scatter</li>
                  <li>Matplotlib Bars</li>
                  <li>Matplotlib Histograms</li>
                  <li>Matplotlib Pie Charts</li>
                </ul>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="table-responsive pb-5">
      <table className="table table-bordered table-striped">
        <thead>
          <tr style={{ backgroundColor: '#F17C00', color: '#fff' }}>
            <th className="text-center" colSpan="5">Django Topics</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <button onClick={() => toggleSection('Operation')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Operations Started
                <span className={`accordion-icon ${expandedSection === 'Operation' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Operation' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Operation' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Django Home</li>
                  <li>Django Intro</li>
                  <li>Django Get Started</li>
                  <li>Create Virtual Environment</li>
                  <li>Install Django</li>
                  <li>Django Create Project</li>
                  <li>Django Create App</li>
                  <li>Django Views</li>
                  <li>Django URLs</li>
                  <li>Django Templates</li>
                  <li>Django Models</li>
                  <li>Django Insert Data</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleSection('Operations')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Basic Operations
                <span className={`accordion-icon ${expandedSection === 'Operations' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Operations' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Operations' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Django Update Data</li>
                  <li>Django Delete Data</li>
                  <li>Django Update Model</li>
                  <li>Display Data</li>
                  <li>Prep Template and View</li>
                  <li>Add Link to Details</li>
                  <li>Add Master Template</li>
                  <li>Add Main Index Page</li>
                  <li>Django 404 Template</li>
                  <li>Add Test View</li>
                  <li>Django Admin</li>
                  <li>Create User</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleSection('Admin & Syntax')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Admin & Syntax
                <span className={`accordion-icon ${expandedSection === 'Admin & Syntax' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Admin & Syntax' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Admin & Syntax' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Include Models</li>
                  <li>Set List Display</li>
                  <li>Update Members</li>
                  <li>Add Members</li>
                  <li>Delete Members</li>
                  <li>Django Syntax</li>
                  <li>Django Variables</li>
                  <li>Django Tags</li>
                  <li>Django If Else</li>
                  <li>Django For Loop</li>
                  <li>Django Comment</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleSection('QuerySet')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>QuerySet & Static Files
                <span className={`accordion-icon ${expandedSection === 'QuerySet' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'QuerySet' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'QuerySet' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Django Include</li>
                  <li>QuerySet Introduction</li>
                  <li>QuerySet Get</li>
                  <li>QuerySet Filter</li>
                  <li>QuerySet Order By</li>
                  <li>Add Static Files</li>
                  <li>Install WhiteNoise</li>
                  <li>Collect Static Files</li>
                  <li>Add Global Static Files</li>
                  <li>Add Styles to the Project</li>
                  <li>PostgreSQL Intro</li>
                  <li>Create AWS Account</li>
                </ul>
              )}
            </td>
            <td>
              <button onClick={() => toggleSection('Deployment')} style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer' }}>
                <b>Deployment
                <span className={`accordion-icon ${expandedSection === 'Deployment' ? 'open' : ''}`}>
                  &nbsp;
                  {expandedSection === 'Deployment' ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </span>
                </b>
              </button>
              {expandedSection === 'Deployment' && (
                <ul style={{ paddingLeft: '1.5rem' }}>
                  <li>Create Database in RDS</li>
                  <li>Connect to Database</li>
                  <li>Add Members</li>
                  <li>Deploy Django</li>
                  <li>Elastic Beanstalk (EB)</li>
                  <li>Create requirements.txt</li>
                  <li>Create django.config</li>
                  <li>Create .zip File</li>
                  <li>Deploy with EB</li>
                  <li>Update Project</li>
                </ul>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

      </div>
    </div>
    </>
  );
}

export default Spython;
