import React from "react";
// import Popup from './Form';
import "../css/courses.css"
import "../css/style.css"
import bn from "../img/cr1.png"; 
import { corporate } from "../../dummydata";
import emailjs from "emailjs-com";
import Accordion from 'react-bootstrap/Accordion';

const Carporate = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_iae8p4o', 'template_8rutgyv', e.target, 'ss92jwsjiWWlkjV1I')
      .then(res => {
        console.log(res);
        if (res) {
          window.location.reload();
        }
      },[])
      .catch(err => console.log(err));
  }

    return(
        <>        
        <div className="row" style={{background: 'linear-gradient(to right, #EBF4F5, #B5C6E0)' }}>
        <div className="container">
        <div className="row" > 
            <div className="col-md-7 col-6 p-5">
            <h1 className="head" style={{ color:'#0193CC'}}><b>
              Corporate Training
</b></h1>
            </div>
            <div className="col-md-5 col-6">
            <img className="" src={bn} alt="img" style={{width:'110%', alignItems:'right'}} />
              </div>          
        
      </div>
        </div>
    </div>
    <div className="row pb-5">
        <div className="container">
            <div className="row pt-5 pb-5">
            <div className="col-md-8 col-12" >
        {/* <h1 className="mb-2" style={{ color: '#F17C00' }}>Java Full Stack Development Course </h1> */}

        <h2 className="mb-3"style={{ color: '#000', fontSize: '33px' }}><b>Corporates Training Overview</b></h2>
        <p>
        Transform your workforce with UpSkill IT Training & Placement’s comprehensive corporate training programs. Designed to meet the unique needs of businesses, our training solutions equip teams with essential skills across a range of technologies and methodologies. Whether you’re looking to enhance software development, data analysis, project management, or IT infrastructure, our programs offer practical, hands-on experience that directly translates to real-world success.
        </p><p>
        Our expert trainers deliver industry-relevant content through engaging workshops and interactive sessions, ensuring your team gains actionable insights and cutting-edge knowledge. We offer flexible training options, including on-site, virtual, and customized solutions tailored to your company's objectives and schedule.
        </p><p>
        At UpSkill, we focus on driving productivity, fostering innovation, and achieving measurable results. Partner with us to elevate your team’s skills, improve performance, and stay ahead in today’s competitive market. Our commitment is to empower your workforce with the tools and knowledge needed to excel and lead in their respective fields.
        </p>

{/* <h2 className="mt-4 mb-3" style={{ color: '#000', fontSize: '33px' }}><b>What is Full stack development course?</b></h2>
          <p style={{ textAlign: 'justify' }}>
            Our comprehensive program covers the entire stack, from front-end to back-end technologies, equipping you with the skills needed to design, build, and deploy robust web applications. Delve into <strong>HTML, CSS, JavaScript,</strong> and popular frameworks for crafting engaging user interfaces. Explore server-side scripting, databases, and server deployment to master the back-end.
          </p> */}
        </div>
        <div className="col-md-4 rounded bg-white p-4 card shadow" >
        <form onSubmit={sendEmail}>
                        <label for="Name" >Your Name <span style={{color:'red'}}>*</span></label>
                        <input name="name" type="text" className="form-control mb-2" placeholder="Enter Name" required/>
                        <label for="Phone" >Phone Number <span style={{color:'red'}}>*</span></label>
                        <input name="phone" type="text" className="form-control mb-2" placeholder="Phone No" maxLength="10" minLength="10" onKeyPress={(event) => isNaN(event.target.value + String.fromCharCode(event.which)) && event.preventDefault()} required />
                        <label for="Qualification" >Email<span style={{color:'red'}}>*</span></label>
                        <input type="text" name="email" className="form-control mb-3" placeholder="Email" required/>
                        <label for="Qualification" >Company Name<span style={{color:'red'}}>*</span></label>
                        <input name="company" type="text" className="form-control mb-2" placeholder="Company Name" required/>
                        {/* <label for="Year of Passing" >Year of Passing<span style={{color:'red'}}>*</span></label> */}
                        {/* <input name="passingy" type="text" className="form-control mb-2" placeholder="Year of Passing" required/> */}
                        {/* <label for="Nearest Traning Hub" >Nearest Traning Hub<span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control" placeholder="Nearest Traning Hub" /> */}
                        <center><button type="submit" className="btn text-white mt-2" style={{background: '#F07D00', borderRadius:'22px', border:'none'}}><b>Request a Callback</b><i className='fa fa-long-arrow-alt-right'></i></button></center>
                    </form>
                </div>
            </div>

            <div className="row text-center">
         <div className="col-md-12 col-12">
         <h2 className="mt-4 " style={{ color: '#000', fontSize: '33px' }}><b>Our Training Programmes</b></h2>
         <p className="mb-3">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
         </div>
            {corporate.map((val, index)=>(
            <div className="col-6 col-md-3 pt-4" key={index} >
            <div className="card shadow p-3 d-flex justify-content-center pcard">
                <img src={val.icon} alt="icon" style={{width:`${val.width}`, margin:'auto'}}/>
                <p>{val.name}</p>
            </div>
            </div>
            ))}  
            </div>

            <div className="row ">
        <div className="col-md-12 col-12  text-center">
        <h2 className="mt-4" style={{ color: '#F17C00', fontSize: '33px' }}>
          <b>Frequently Asked Questions</b>
        </h2>
        <p className="mb-4">Empowering Your Career Journey: Placement Solutions Tailored for Success</p>
        </div>

        <div className="col-12 col-md-12">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordion-header-custom"> What is a Corporate Training Course?</Accordion.Header>
              <Accordion.Body className="accordion-body-custom">
             
              Corporate Training Course is also referred to as Workplace Learning or Corporate Education, it is a process that helps to train employees using a systematic set of learning programs designed to nurture employees skills and knowledge to improve performance in the workplace.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="accordion-header-custom"> Is a Corporate Training Program necessary?</Accordion.Header>
              <Accordion.Body className="accordion-body-custom">
             
              Yes, the Corporate Training Program is important. Corporate Training's Program main aim is to offer your employees skills and in-depth knowledge. It reduces the skills gap, increases productivity, and increases employee retention.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="accordion-header-custom">  Why is the Corporate Finance Course important for Enterprise?</Accordion.Header>
              <Accordion.Body className="accordion-body-custom">
            
              Corporate Finance Course benefits organizations and employees alike by ensuring swift acquisition needed to accomplish corporate goals and success, as well as improving teamwork, employee satisfaction, and retention as well as each employee’s personal skill set, job value, and career development.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="accordion-header-custom"> What skills are covered in the Corporate Training Program?</Accordion.Header>
              <Accordion.Body className="accordion-body-custom">
             
              Corporate Training Course in more than 15 specialized programs that are focused around four core industry verticals, including Digital Business, Technology, Data Science & Artificial Intelligence (AI), and Digital Operations, Software Development, Data Science, AI, Agile & Scrum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="accordion-header-custom">What are Live Virtual Classrooms?</Accordion.Header>
              <Accordion.Body className="accordion-body-custom">
              
              Live Virtual classrooms are live sessions led by expert instructors, scheduled at convenient times. Corporate Finance Course program helps Learners interact with other learners while participating in hands-on labs and projects that simulate real-life application of the course material.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
        </div>
    </div>
     </>
    )
}

export default Carporate