import React from "react";
// import { Link } from "react-router-dom";
// import Popup from './Form';
// import Heading from "./Heading";
import "../css/Hero.css";
// import Img1 from "../img/crous2.png";
// import Img2 from "../img/mobileview.webp"; 
// import banner from "../../assets/Untitled design.png"

const Hero = () => {

  // const [isPopupVisible, setIsPopupVisible] = useState(false);

  // const togglePopup = () => {
  //   setIsPopupVisible(!isPopupVisible);
  // };

  return (
    <div className="container-fluid pb-2">
    <div className="row hero" >
      {/* <h1>Heading</h1> */}
    </div>
    <div className="row hero2" style={{   }}>
  <div className="container " >
    <h1 className="herohead" >Empowering Your IT Career with Expert Training</h1>
    <div className="row pt-2">
      <div className="col-md-9">
        <p style={{  }} >
          <strong>UpSkill</strong> is a leading <strong>IT Training Institute in Pune, </strong> dedicated to providing high-quality education and skill development in the field of Information Technology.
          Join our vibrant community of tech enthusiasts and leverage our state-of-the-art facilities to unlock your potential and transform your career.
        </p>
      </div>
    </div>
  </div>  
</div>

    </div>
  )
}

export default Hero;
