import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { courses } from "../../dummydata";
import Popup from './Form';
import "../css/style.css";

const CoursesCard = ({ link }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClick = () => {
    navigate(link);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="container pb-4">
      <div className="row justify-content-center p-2 crcard">
        <h1>Our Most Popular IT Training Courses</h1>
        <p className="text-center h6 text-secondary">
          Software Industry Offers High-Paying Jobs for the most demanding IT Courses - Java, Selenium, Python, AWS, Azure, Salesforce
        </p>
      </div>
      <div className="row pt-2">
        {courses.map((val, index) => (
          <div className="col-md-6 col-12 pt-4" key={index}>
            <div className="card shadow gradient-border" style={{ borderTopColor: val.color }}>
              <div className="row rounded p-2">
                <div className="col-12 col-md-4" style={{ background: '#e6e6e6' }}>
                  <img src={val.cover} alt={val.title} className='img-fluid im1' style={{ marginTop: `${val.mtop}`, background: '#e6e6e6' }} />
                </div>
                <div className="col-12 col-md-8 pt-4">
                  <p style={{ color: '#000', fontSize: '27px' }}><b>{val.title}</b></p>
                  <p className="mb-0" style={{ fontSize: '19px' }}><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{val.duration}</p>
                  <p className="pt-2" style={{ color: '#F07D00', fontSize: '19px' }}>{val.batch}</p>
                  <div className="row pb-4">
                    <div className="col-6 col-md-6">
                    <Link to={`/${val.link}`}>
  <button
    onClick={handleClick}
    style={{ height: '45px', background: '#0092CB', fontSize:'14px' }}
    className="btn btn-primary rounded"
  >
    View Syllabus
  </button>
</Link>

                    </div>
                    <div className="col-6 col-md-6">
                      <button
                        style={{ height: '45px', background: '#F07D00', fontSize:'14px' }}
                        className="btn btn-secondary rounded"
                        onClick={togglePopup}
                      >
                        Enroll Now
                      </button>
                      {isPopupVisible && <Popup handleClose={togglePopup} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesCard;
