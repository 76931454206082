
import React from "react";
import "../css/courses.css";
import "../css/style.css";
// import { coursesCard } from "../../dummydata";
import bn from "../img/cr2.png";
import img1 from "../img/about1.gif"
// import img2 from "../img/about3.gif"
import img3 from "../img/about4.gif"


const AboutCard = () => {
  return (
    <>
    <div className="row" style={{background: 'linear-gradient(to right, #EBF4F5, #B5C6E0)' }}>
        <div className="container">
        <div className="row" > 
            <div className="col-md-7 col-6 p-5">
            <h1 className="head" style={{ color:'#0193CC'}}><b>
              About Us
</b></h1>
            </div>
            <div className="col-md-5 col-6">
            <img className="" src={bn} alt="img" style={{width:'110%', alignItems:'right'}} />
              </div>          
        
      </div>
        </div>
    </div>
    <div className="container">

      <div className="row justify-content-center">
        <h1><b>Welcome to Upskill Institute</b></h1>      
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
        <p >At Upskill Institute, we are dedicated to transforming individuals into skilled IT professionals ready to excel in the dynamic world of technology. Our institute offers comprehensive training programs and placement services designed to bridge the gap between academic knowledge and industry demands.</p> 
        </div>      
      </div>
       
      <div className="row">
        <div className="col-md-6 ">
        <img src={img1} alt="img1" style={{width:'100%'}}/>
        
        </div>      
        <div className="col-md-6 text-center pt-5">
        <h1>Our Mission</h1> 
        <p >At Upskill Institute, we are dedicated to transforming individuals into skilled IT professionals ready to excel in the dynamic world of technology. Our institute offers comprehensive training programs and placement services designed to bridge the gap between academic knowledge and industry demands.</p> 
        <h1 className="pt-3">Our Vision</h1>  
        <p >We envision a future where every student has the opportunity to achieve their full potential in the IT field. By fostering a culture of continuous learning and innovation, we aim to be a leading institution that produces highly competent and industry-ready professionals.</p> 
        </div>      
      </div>
    
      <div className="row pt-5">
      <div className="col-md-6 ">
      <h1>What We Offer</h1> 
      <div className="row" >
          <ol >
        <h5><b>1. Comprehensive Training Programs</b></h5>
            <li><b>Programming Languages :</b> Master languages such as Java, Python and more.</li>
            <li><b>Web Development :</b> Learn HTML, CSS, JavaScript, and modern frameworks.</li>
            <li><b>Database Management :</b> Gain expertise in SQL, NoSQL, and database design.</li>
            <li><b>Software Development :</b> Understand the software development lifecycle and methodologies.</li>
          </ol>
          <ol >
        <h5><b>2. Hands-on Experience</b></h5>
            <li><b>Real-world Projects :</b> Work on live projects to apply theoretical knowledge.</li>
            <li><b>Internships :</b>  Gain practical experience through internships with leading companies.</li>
            <li><b>Workshops and Seminars :</b>  Participate in industry-relevant workshops and seminars.</li>
          </ol>
          <ol >
        <h5><b>3. Placement Services</b></h5>
            <li><b>Career Counseling :</b> Receive personalized career guidance and counseling.</li>
            <li><b>Resume Building :</b> Craft a professional resume that stands out.</li>
            <li><b>Interview Preparation :</b> Prepare for interviews with mock sessions and expert tips.</li>
            <li><b>Job Placement Assistance :</b> Connect with top companies for job placements.</li>
          </ol>
        </div>
        </div>    
        <div className="col-md-6 col-12">
        <img src={img3} alt="img1" style={{height:'100%', width:'100%'}}/>
        
        </div>      
          
      </div>
      {/* <div className="row pt-5">
      <div className="col-md-6 ">
        
        <img src={img2} alt="img1" style={{height:'100%', width:'100%'}}/>
        </div> 
      <div className="col-md-6 ">
      <h1>Why Choose Us ?</h1> 
      <div className="row pt-2" >
          <ul >
            <li><b>Experienced Faculty :</b>  Learn from industry experts and seasoned professionals.</li>
            <li><b>State-of-the-Art Facilities :</b> Train in a modern, well-equipped learning environment.</li>
            <li><b>Flexible Learning Options :</b> Choose from full-time, part-time, and online courses.</li>
            <li><b>Student Support :</b> Access continuous support and mentorship throughout your journey.</li>
          </ul>
        
        </div>
        </div>        
          
      </div> */}

    </div>
    </>
  );
}

export default AboutCard; 
