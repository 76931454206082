import React from "react"
import "../css/about.css"
// import Back from "../common/back/Back"
import AboutCard from "./AboutCard"
// import aboutimg1 from "../img/aboutbackgroundGIF.gif"
// import aboutimg1 from "../../assets/about2.avif"


const About = () => {
  return (
    <>
{/* 
   <div class="about-section "> <h1 style={{marginBottom: '50px', position:'absolute', padding:'70px', color:'#E0EBD1', fontSize:"60px"}}><b>About Us</b></h1>
  <img className="pb-5" src={aboutimg1} alt="aboutimg" style={{ width: "100%" }} />
  
    <h2 style={{marginTop:'-200px'}}>About</h2>
  
</div> */}
       {/* <Back title='About Us' /> */}
      <AboutCard />
    </>
  )
}

export default About 