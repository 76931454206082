import React from 'react';
import '../css/style.css'; 
import emailjs from "emailjs-com";


const Popup = ({ handleClose }) => {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_iae8p4o', 'template_wdlflua', e.target, 'ss92jwsjiWWlkjV1I')
      .then(res => {
        console.log(res);
        if (res) {
           window.location.reload();
        }
      },[])
      .catch(err => console.log(err));
  }

  return (
    <div className="container popup ">

      <div className="row content" style={{marginLeft:'5px', paddingRight:'15px'}}>
      
      <form className="" onSubmit={sendEmail}>
        <div className='row'>
        <div className='col-md-10'> <span style={{fontSize: '23px'}}>Book a<b> Free Demo Class</b> </span></div>
        <div className='col-md-1'> <span className="text-end" onClick={handleClose}><i class="fa-solid fa-xmark"></i></span></div>
        </div>   
     
      <span style={{color:'#F07D00'}} >&nbsp;&nbsp;&nbsp;Exciting offers are available for you. Hurry Up!!!</span>
              {/* <center> <h3>Contact Us</h3></center> */}
              <label className='pt-2' htmlFor="name">Your Name<span style={{color:'red'}}>*</span></label>
              <input type="text" name="name" id="name" className="form-control " placeholder="Enter Name" required />
              <label htmlFor="email">Your Email<span style={{color:'red'}}>*</span></label>
              <input type="text" name="email" id="name" className="form-control " placeholder="Enter email" required />
              <label htmlFor="phone">Phone Number<span style={{color:'red'}}>*</span></label>
              <input type="text" name="phone" id="phone" className="form-control " placeholder="Phone No" maxLength="10" minLength="10" onKeyPress={(event) => isNaN(event.target.value + String.fromCharCode(event.which)) && event.preventDefault()} required />
              <label htmlFor="subject">Education<span style={{color:'red'}}>*</span></label>
              <input type="text" name="qualification" id="subject" className="form-control " placeholder="Enter Education.." required />
              <label for="Year of Passing" >Year of Passing<span style={{color:'red'}}>*</span></label>
              <input name="yop" type="text" className="form-control " placeholder="Year of Passing" required/>
              <label htmlFor="message">Choose Course<span style={{color:'red'}}>*</span></label>
              <select type="text" name="course" id="message" className="form-control " required>
                <option value="">Choose Course</option>
                <option value="Web Development">Web Development</option>
                <option value="Software Testing">Software Testing</option>
                <option value="Java Full Stack Development">Java Full Stack Development</option>
                <option value="Python Full Stack Development">Python Full Stack Development</option>
              </select>
              {/* <input  placeholder="Your Message..."  /> */}
              <center><button type="submit" className="btn btn-primary mt-2"><b>Submit</b></button></center>
            </form>
      </div>
    </div>
  );
};

export default Popup;
