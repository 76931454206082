import React, {useState} from "react";
import Popup from './Form';
import "../css/courses.css"
// import img1 from "../img/new3.png"
import { Link } from "react-router-dom";
import img2 from "../img/webdev2.gif"
import img3 from "../img/cr1.gif"

const WebTech = () => {

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = () => {
      setIsPopupVisible(!isPopupVisible);
    };

    return(
        <>
             <div className="row">
        <img src={img3} alt="img" width={'100%'} />
      </div>
        <div  className="container pb-5 pt-5">
            <div className="row p-2">
                <div className="col-md-8 p-2">
                    <span style={{color:'#F17C00'}}><b>Become a Certified</b></span>
                    <h2><b>Web Development </b></h2>
                    <p style={{textAlign:'justify'}}>Embark on a transformative journey into the dynamic realm of<strong> Web Development with Upskill IT Training & Placement.</strong> Web development, also known as website development, refers to the tasks associated with creating, building, and maintaining websites and web applications that run online on a browser. It may, however, also include web design, web programming, and database management.
                    </p>
                   
                    {/* <h2><b>Web Development Syllabus</b></h2>
                    <table style={{textAlign:'justify'}}>
                        <tr>
                            <th>HTML</th>
                            <th>CSS</th>
                        </tr>

                        <tr>
                            <td>• HTML and CSS</td>
                            <td>• Introduction with CSS</td>
                        </tr>
                        <tr>
                            <td>• Introduction to HTML</td>
                            <td>• The STYLE Element</td>
                        </tr>
                        <tr>
                            <td>• HTML Tags and Elements</td>
                            <td>• Measurement Units</td>
                        </tr>
                        <tr>
                            <td>• HTML – Attributes</td>
                            <td>• Colors,Backgrounds</td>
                        </tr>
                        <tr>
                            <td>• HTML - Formattings</td>
                            <td>• Fonts,Texts,Images</td>
                        </tr>
                        <tr>
                            <td>• HTML – Phrase Tags</td>
                            <td>• Tables</td>
                        </tr>
                        <tr>
                            <td>• HTML - MetaTags</td>
                            <td>• Borders</td>
                        </tr>
                        <tr>
                            <td>• HTML - Comments</td>
                            <td>• TMargins</td>
                        </tr>
                        <tr>
                            <td>• HTML - Image tags</td>
                            <td>• Paddings</td>
                        </tr>
                        <tr>
                            <td>• HTML - Tables</td>
                        </tr>
                        <tr>
                            <td>• HTML - Iframe</td>
                        </tr>
                        <tr>
                            <td>• HTML - Blocks</td>
                        </tr>
                        <tr>
                            <td>• HTML - Background</td>
                        </tr>
                        <tr>
                            <td>• HTML - Colors, Fonts</td>
                        </tr>
                  <tr>
                     <li>• JavaScript</li>
                     <li>• Front-end development</li>
                     <li>• Full-stack web development</li>
                     <li>• Dynamic website development</li>
                     <li>• ReactJS development</li>
                     <li>• Back-end development</li>
                     <li>• Full-stack deployment</li>
                  </tr>
                </table> */}
                   


                    <div className="row p-1">
                        <div className="col-lg-6"><button onClick={togglePopup} className="btn  a2"><b>Access the demo lecture Now</b><i className='fa fa-long-arrow-alt-right'></i></button></div>
                        <div className="col-lg-6"><button onClick={togglePopup} className="btn a3"><b>Download Web Development Syllabus</b><i className='fa fa-long-arrow-alt-right'></i></button></div>                  
            </div>
                </div>
                <div className="col-md-4 rounded bg-white p-4 card" style={{height: '420px'}}>
                    <form>
                        <label for="Name" >Your Name <span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control mb-2" placeholder="Enter Name" required/>
                        <label for="Phone" >Phone Number <span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control mb-2" placeholder="Phone No" maxLength="10" minLength="10" onKeyPress={(event) => isNaN(event.target.value + String.fromCharCode(event.which)) && event.preventDefault()} required />
                        <label for="Qualification" >Qualification<span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control mb-2" placeholder="Qualification" required/>
                        <label for="Year of Passing" >Year of Passing<span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control mb-2" placeholder="Year of Passing" required/>
                        {/* <label for="Nearest Traning Hub" >Nearest Traning Hub<span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control" placeholder="Nearest Traning Hub" /> */}
                        <center><button type="submit" className="btn text-white mt-4" style={{background: '#F07D00', borderRadius:'22px', border:'none'}}><b>Request a Callback</b><i className='fa fa-long-arrow-alt-right'></i></button></center>
                    </form>
                </div>
            </div>
            <div className="row p-5">
                <div className="col-md-12 card p-3">
                    <div className="row">
                        <div className="col-md-4 ">
                            <div className="row">
                            <div className="col-md-3 text-center p-2" >
                            <img src="https://thekiranacademy.com/img/orange-calender-img.svg" alt="ab"/>
                            </div>
                            <div className="col-md-9 p-3 ">
                            <span><b>&nbsp;&nbsp;&nbsp;Course Duration</b></span><br/>
                            <span>&nbsp;&nbsp;&nbsp;3 Months Course Duration</span><br/>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="row">
                        <div className="col-md-3 text-center p-2" >
                            <img src="https://thekiranacademy.com/img/blue-calender-img.svg" alt="ab"/>
                            </div>
                            <div className="col-md-9 p-2 ">
                            <span><b>&nbsp;&nbsp;Upcoming Web Development Batch
                            &nbsp;&nbsp;Starting on Mon, 1st Apr 24</b></span><br/>
                            <span>&nbsp;&nbsp;Limited seats available</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-3 text-center p-2" >
                            <img src="https://thekiranacademy.com/img/green-calender-img.svg" alt="ab"/>
                            </div>
                            <div className="col-md-9 p-3 ">
                            <span><b>&nbsp;&nbsp;&nbsp;Placement Assistance</b></span><br/>
                            <span>&nbsp;&nbsp;&nbsp;Get 100% Job Assistance</span><br/>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-5">
                    <div className="row px-5">
                    <div className="col-12 col-lg-6"><button className="btn  a5"><b>View Web Development Tutorial</b></button></div>
                    <div className="col-12 col-lg-6"><button className="btn a6"><b>Claim 100% Scholarsip</b></button></div> 
                    </div>                                        
            </div>
            </div>
            <div className="row">
            <div className="col-md-12"><span style={{color:'#F17C00', fontSize:'23px'}}><b>What is Web Development Course?</b></span></div>
            <div className="col-md-12"><span style={{color:'#000', fontSize:'33px'}}><b>What is Web Development Course?</b></span></div>           
            <div className="col-md-12"><span style={{color:'#000', fontSize:'33px'}}><p style={{textAlign:'justify'}}>Web Development Developer Certification Program Ensures That You Master The Core Concepts & Components Of Application Development On The Platform. Our Web Development Classes In Pune Will Help You Improve Your Skills In A Few Months. Our Web Development Classes In Pune Are Beneficial For Your Career Growth.</p></span></div>         
             </div>
            <div className="row pt-5">
                <div className="col-md-12 text-center "><h2>Web Development Certification Programme Highlights</h2></div>
                <div className="col-12 col-md-6 p-5">
                        <div className="row shadow rounded p-3" style={{height:'110px'}}>
                        <div className="col-md-2 ">
                            <img style={{width:'130%'}} src="https://thekiranacademy.com/img/details-img4.svg" alt="imw"/>
                        </div>
                        <div className="col-md-9 pt-3">
                            
                            <span style={{fontSize:'22px'}}><b>25 Hrs Online Session</b></span>
                        </div>
                    </div>
                </div>
                <div className=" col-12 col-md-6 p-5">
                <div className="row shadow rounded p-3" style={{height:'110px'}}>
                        <div className="col-md-2 ">
                            <img style={{width:'130%'}} src="https://thekiranacademy.com/img/details-img5.svg" alt="imw"/>
                        </div>
                        <div className="col-md-9 pt-1">
                            
                            <span style={{fontSize:'22px'}}><b>Training as per Web Development Certification Guide</b></span>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 col-md-6 px-5">
                        <div className="row shadow rounded p-3" style={{height:'110px'}}>
                        <div className="col-md-2 ">
                            <img style={{width:'130%'}} src="https://thekiranacademy.com/img/details-img6.svg" alt="imw"/>
                        </div>
                        <div className="col-md-9 pt-3">
                            
                            <span style={{fontSize:'22px'}}><b>3 Projects, 1 Case Studies</b></span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 px-5">
                <div className="row shadow rounded p-3" style={{height:'110px'}}>
                        <div className="col-md-2 ">
                            <img style={{width:'130%'}} src="https://thekiranacademy.com/img/details-img7.svg" alt="imw"/>
                        </div>
                        <div className="col-md-9 pt-1">
                            
                            <span style={{fontSize:'22px'}}><b>Certification after Course Completion</b></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-md-6">
                    <img src={img2} alt="img" style={{width:'100%'}} />
                </div>
                <div className="col-md-6 liul">
                    <ul>
                        <li> <h2>Hands-on Practice Covered</h2></li>                       
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Develop custom web-based applications</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Frameworks- Hibernate, and Spring</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Design and manage a database</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Learn Security & Vulnerabilities Concept</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Describe the testing framework</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Execute multiple tests</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Create custom applications</li>
                        <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00', fontSize: '22px' }}>&nbsp;</i>Deploy Project</li>
                        <li><Link to='/Swebd'><button className="btn a7">View Syllabus<i className='fa fa-long-arrow-alt-right'></i></button></Link></li>
                    </ul>
                </div>
            </div>

           
        </div>
        <div className="container-fluid" >
            <div className="row" style={{background:'#80ccff' }}>
            <div className="container">
                <div className="row p-3 liul">
         <div className="col-md-12 text-center p-3"><h2>Our Web Development Training Program | Trainer Profile</h2></div>
         <div className="col-md-12 "><p style={{textAlign:'justify'}}>
         Though you have joined online Web Development classes or classroom training for Web Development batch, we have in-house trainers with a strong profile of at least more than 7+ years of experience in Developing Web Development applications, International Project Handling, having knowledge of modern tools and techniques to execute Web Development Coding. The Trainer who executes Web Development fresher to advance batch online or in the classroom training must have the following highlights of their profile:
             </p></div>
             <ul className="">
                 <li></li>
                 <li></li>
                 {/* <li> <h2>Hands-on Practice Covered</h2></li>                        */}
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>7+ years of Web Development and project execution experienced</li>
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>Having a record of the trained student list of more than 1500 in a year</li>
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>Ex-MNC or Indian MNC faculty to guide you in a more corporate fashion.</li>
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>Web Development sun-certified / having the highest grade in the past experience</li>
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>Web Development domain expert to answer and solve your question with real-life examples only</li>
                 <li><i className="fa-solid fa-circle-check pt-1" style={{ color: '#F07D00' }}>&nbsp;</i>Participated in national & international conferences under Web Development development programs</li>
                 <li><button onClick={togglePopup} className="btn a7" style={{width:'50%'}}><b>Meet Our Web Development Trainer</b><i className='fa fa-long-arrow-alt-right'></i></button></li>
             </ul>
     </div>
            </div>
            </div>

        </div>
         <div className="container  pb-5">
            <div className="row">
            <div className="col-md-12 text-center p-5"><h2>Popular Software Application Programs Written in Web Development</h2></div>
                <div className="col-md-3 ">
                    <div className="p-4 shadow card">
                    <div className="row ">
                        <img src="https://thekiranacademy.com/img/Spotify.svg" alt="img" />
                    </div>
                    <div className="row pt-3"><h4>Spotify</h4></div>
                    <div className="row ">
                        <p >
                        Spotify: Best music streaming app for enjoying music and podcasts anytime, anywhere.
                        </p>
                    </div>

                    </div>

                </div>
                <div className="col-md-3">
                <div className="p-4 shadow card">
                    <div className="row ">
                        <img src="https://thekiranacademy.com/img/Twitter.svg" alt="img" />
                    </div>
                    <div className="row pt-3"><h4>Twitter</h4></div>
                    <div className="row ">
                        <p >
                        Twitter: Popular social media app for users to tweet their thoughts globally.<br/><br/>
                        </p>
                    </div>
                </div>
                </div>
                <div className="col-md-3">
                <div className="p-4 shadow card">
                    <div className="row ">
                        <img src="https://thekiranacademy.com/img/Operamini.svg" alt="img" />
                    </div>
                    <div className="row pt-3"><h4>Opera Mini</h4></div>
                    <div className="row ">
                        <p >
                        Opera Mini: Top mobile web browser offering excellent online browsing experience.<br/><br/>
                        </p>
                    </div>
                </div>
                </div>
                <div className="col-md-3">
                <div className="p-4 shadow card">
                    <div className="row ">
                        <img src="https://thekiranacademy.com/img/Nimbuzz.svg" alt="img" />
                    </div>
                    <div className="row pt-3"><h4>Nimbuzz Messenger</h4></div>
                    <div className="row ">
                        <p >
                        Nimbuzz: Famous for instant messaging with an API enhancing user chat experience.
                        </p>
                    </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center pt-3">
            <button onClick={togglePopup} className="btn a8" style={{width:'50%'}}><b>Enroll Web Development course and Build your Own Application</b><i className='fa fa-long-arrow-alt-right'></i></button>
            {isPopupVisible && <Popup handleClose={togglePopup} />}
            </div>
         </div>
     </>
    )
}

export default WebTech